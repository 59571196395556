import { makeStyles } from "@mui/styles";

export const useSyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr ",
    },
  },
  title: {
    textAlign: "center",
    fontSize: "32px !important",
    color: "#fff",
    fontWeight: 700 + "!important",
  },
  headings: {
    fontSize: "18px !important",
    color: "#fff",
    fontWeight: 700 + "!important",
  },
  description: {
    fontSize: "16px !important",
    color: "#fff",
    fontWeight: 400 + "!important",
  },
  image: {
    width: "500px !important",
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
    },
  },
}));
