import { Box, Typography } from "@mui/material";
import NetworkTable from "../networkTable";

const ArtWorkHistory = () => {
  return (
    <Box>
      <Box py={2} textAlign="center">
        <Typography sx={{ fontSize: "36px", fontWeight: 700, color: "#fff" }}>
          Artwork History
        </Typography>
      </Box>
      <Box>
        <NetworkTable />
      </Box>
    </Box>
  );
};

export default ArtWorkHistory;
