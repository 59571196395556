import { Box, Typography, useMediaQuery } from "@mui/material";
import logo from "../../assets/logo/nftLogo.png";
import { useStyles } from "./style";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import tiktokIcon from "../../assets/icons/tiktok.jpg";
import { Link } from "react-router-dom";
const Footer = () => {
  const classes = useStyles();
  const mobile = useMediaQuery("(min-width: 600px)");
  const Ismobile = useMediaQuery("(max-width: 600px)");
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <img src={logo} className={classes.image} alt="" />
        <Typography className={classes.description}>
          Welcome to xNFTs: Where AI Art Meets Exclusivity. Explore a curated
          collection of cutting-edge digital masterpieces, redefining the
          horizon for art enthusiasts. Elevate your experience with the pinnacle
          of quality and uniqueness in the world of NFTs.
        </Typography>
        {mobile && <Typography pt={3}>2024 All Right Reserved</Typography>}
      </Box>
      <Box className={classes.rightContainer}>
        <Box className={classes.linksContainer}>
          <Typography className={classes.heading}>Legal</Typography>

          <Typography
            className={classes.description}
            component={Link}
            to="/about-us"
          >
            About us
          </Typography>
        </Box>
        <Box className={classes.linksContainer}>
          {" "}
          <Typography className={classes.heading}>Help</Typography>
          <Typography
            className={classes.description}
            component={Link}
            to="/policies"
          >
            Terms of Service
          </Typography>
          <Typography
            component={Link}
            to="/legaldisclaimer"
            className={classes.description}
          >
            Legal Disclaimer{" "}
          </Typography>
        </Box>
        <Box className={classes.linksContainer}>
          {" "}
          <Typography className={classes.heading}>Contact</Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Link
              to="https://www.instagram.com/xnfts.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <InstagramIcon className={classes.icon} />
            </Link>
            <Link
              to="https://twitter.com/xNFTs_AI"
              target="_blank"
              rel="noopener noreferrer"
            >
              <XIcon className={classes.icon} />
            </Link>
            <Link
              to="https://www.tiktok.com/@xnfts.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={tiktokIcon}
                className={classes.icon}
                alt="/"
                width={"25px"}
              />
            </Link>
          </Box>
        </Box>
      </Box>
      {Ismobile && (
        <Box pt={10}>
          <Typography>2024 All Right Reserved</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Footer;
