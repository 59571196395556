// DynamicAppBar.js
import React from "react";
import { makeStyles } from "@mui/styles";
import { AppBar, Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";

const DynamicTabBar = ({ tabData, onHandleChange, tabValue }) => {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.appBar}>
      <Tabs
        value={tabValue}
        onChange={onHandleChange}
        aria-label="basic tabs example"
        className={classes.tabsCollection}
      >
        {tabData.map((tab, index) => (
          <Tab
            key={tab.id}
            icon={
              tab.icon && (
                <img src={tab.icon} alt={tab.alt} className={classes.tabIcon} />
              )
            }
            label={tab.label}
            className={classes.tab}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default DynamicTabBar;

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: "fit-content !important",
    backgroundColor: "#FFFFFF20 !important",
    borderRadius: "1rem",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0.5rem",
    },
  },

  tabsCollection: {
    minHeight: "40px !important",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      minHeight: "30px !important",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "25px !important",
    },
  },

  tab: {
    minWidth: "36px !important",
    minHeight: "40px !important",
    padding: "0 15px !important",
    color: "#FFFFFF99 !important",
    fontWeight: "700",
    "&.Mui-selected": {
      backgroundColor: "#FFFFFF60 !important",
      borderRadius: "1rem",
      color: "#FFFFFF !important",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "36px !important",
      minHeight: "30px !important",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "23px !important",
      minHeight: "25px !important",
      padding: "0px 9px !important",
      fontSize: "6.46px !important",
      "&.Mui-selected": {
        borderRadius: "0.5rem",
      },
    },
  },

  tabIcon: {
    width: "20px",
    height: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "12px",
      height: "12px",
    },
  },
}));
