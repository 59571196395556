import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllArtsApi,
  getArtHistoryApi,
  getBitcoinDataApi,
  getSingleArtsApi,
} from "./api";

export const getAllArts = createAsyncThunk("arts/allArts", async (payload) => {
  try {
    const res = await getAllArtsApi(payload);
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const getSingleArts = createAsyncThunk(
  "arts/singleArts",
  async (payload) => {
    try {
      const res = await getSingleArtsApi(payload);
      return res;
    } catch (error) {
      throw error;
    }
  }
);
////////////////////////////////////////////////////////////////////////
export const getArtHistory = createAsyncThunk(
  "arts/getArtHistory",
  async (payload) => {
    try {
      const res = await getArtHistoryApi(payload);
      return res;
    } catch (error) {
      throw error;
    }
  }
);
// bit coin
export const getBitCoinData = createAsyncThunk(
  "arts/bitCoin",
  async (payload) => {
    try {
      const res = await getBitcoinDataApi(payload);
      return res;
    } catch (error) {
      throw error;
    }
  }
);
