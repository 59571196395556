// TabPanel.js
import React from "react";

import TrendingCollectionTable from "../../TableMain";
import CustomTabPanel from "../customTabPanel";

const CardCollectionPanel = ({ index, value, tabContents }) => {
  const { tableColumnData, tableRowsData } = tabContents[index] || {};

  return (
    <CustomTabPanel value={value} index={index}>
      <TrendingCollectionTable
        tableColumnData={tableColumnData}
        tableRowsData={tableRowsData}
      />
    </CustomTabPanel>
  );
};

export default CardCollectionPanel;
