import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeNetwork } from "../../redux/mint/mint.action";
import { Polygon, Ethereum, Avalanche, Base } from "@thirdweb-dev/chains";
import { MenuItem, Select } from "@mui/material";
import avalancheIcon from "../../assets/icons/adidas.png";
import baseIcon from "../../assets/icons/base.png";
import EthIcon from "./../../assets/icons/ethIcon.png";
import PolgonIcon from "./../../assets/icons/matic.png";
import { makeStyles } from "@mui/styles";
const MyDropdown = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedChain, setSelectedChain] = useState(JSON.stringify(Polygon));

  useEffect(() => {
    dispatch(changeNetwork(selectedChain));
  }, [dispatch, selectedChain]);
  const handleChange = (value) => {
    setSelectedChain(value);
  };

  return (
    <Select
      style={{
        color: "white",
        backgroundColor: "rgba(255, 255, 255, 0.10)",
        borderRadius: "10px",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "bold",
        border: "none",
        display: "flex",
        alignItems: "center",
      }}
      className={classes.formControl}
      value={selectedChain}
      onChange={(e) => {
        handleChange(e.target.value);
      }}
    >
      <MenuItem
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
        value={JSON.stringify(Ethereum)}
      >
        <img src={EthIcon} width={"25px"} alt="" /> <span>ETH</span>
      </MenuItem>
      <MenuItem
        value={JSON.stringify(Polygon)}
        style={{
          color: "black",
          fontSize: "14px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        <img src={PolgonIcon} width={"25px"} alt="" />
        <span>MATIC</span>
      </MenuItem>
      <MenuItem
        value={JSON.stringify(Base)}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <img src={baseIcon} width={"25px"} alt="" /> <span>BASE</span>
      </MenuItem>{" "}
      <MenuItem
        value={JSON.stringify(Avalanche)}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <img src={avalancheIcon} width={"25px"} alt="" /> <span>AVAX</span>
      </MenuItem>
    </Select>
  );
};

export default MyDropdown;
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 100,
    backgroundColor: "#2B2140",
    color: "#fff",
    borderRadius: "10px",
    "& .MuiInputBase-input": {
      color: "#fff",
      padding: theme.spacing(0.5, 1),
    },
    "& .MuiSvgIcon-root": {
      fill: "#fff",
      marginLeft: theme.spacing(3),
    },
    "& .MuiSelect-select": {
      borderRadius: "10px",
      display: "flex",
      padding: "15px 15px",
      alignItems: "center",
      gap: "5px",
      backgroundColor: "#2B2140",
      "&:focus": {
        backgroundColor: "#2B2140",
        border: "none",
      },
    },
  },
  paper: {
    "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
      backgroundColor: "none !important",
    },
    "& .MuiList-root": {
      backgroundColor: "#2B2140",
      border: "none",
      color: "#fff",
    },
  },
}));
