import { Button as MuiButton } from "@mui/material";
import { withStyles } from "@mui/styles";

const Button = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary + " !important",
    fontSize: "15px !important",
    // boxShadow: "none !important",
    textTransform: "capitalize !important",
  },

  contained: {
    fontFamily: "Clash Display,sans-serif !important",
    backgroundColor: "#7879F1",
    fontWeight: 300 + " !important",
    letterSpacing: 1.6,
    padding: "5px 30px !important",
    borderRadius: "50px !important",
    "&:hover": {
      backgroundColor: "#7879F0 !important",
    },
  },
  outlined: {
    position: "relative",
    fontWeight: 600 + " !important",
    color: "#7879F1 !important",
    borderRadius: "50px !important",
    padding: "5px 30px !important",
    "&:before": {
      content: "''",
      position: "absolute",
      borderRadius: "2rem !important",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "#7879F1",
      "-webkit-mask":
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      "-webkit-mask-composite": "destination-out",
    },
  },
}))(MuiButton);

export default Button;
