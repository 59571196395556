import { Avatar, Box } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { uploadProfile } from "../../../redux/profile/profile.action";
import { profileBaseURL } from "../../../http/config";
import noProfile from "../../../assets/images/noprofile.jpg";
const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "SVG"];
const UploadProfile = () => {
  const {
    user: { walletAddress, img },
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      wallet: "",
      profile: "",
    },
  });

  const handleChange = (file) => {
    if (file) {
      formik.setFieldValue("profile", file);
      const reader = new FileReader();
      reader.onload = () => {
        const fd = new FormData();
        fd.append("profile", file);
        fd.append("wallet", walletAddress);
        dispatch(uploadProfile(fd));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <FileUploader handleChange={handleChange} name="file" types={fileTypes}>
      {!formik.values.profile ? (
        <Box sx={{ cursor: "pointer" }}>
          <Avatar
            alt="Remy Sharp"
            src={img === null ? noProfile : profileBaseURL + img}
            sx={{ width: "100px", height: "100px" }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 5,
              right: 5,
              background: "#E3FF00",
              color: "#000",
              borderRadius: "50px",
              display: "flex",
              padding: 0.5,
            }}
          >
            <CameraAltIcon sx={{ fontSize: "14px" }} />
          </Box>
        </Box>
      ) : (
        <Box sx={{ cursor: "pointer" }}>
          <Avatar
            alt="Remy Sharp"
            src={
              formik.values.profile &&
              URL.createObjectURL(formik.values.profile)
            }
            sx={{ width: "100px", height: "100px" }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 5,
              right: 5,
              background: "#E3FF00",
              color: "#000",
              borderRadius: "50px",
              display: "flex",
              padding: 0.5,
            }}
          >
            <CameraAltIcon sx={{ fontSize: "14px" }} />
          </Box>
        </Box>
      )}
    </FileUploader>
  );
};
export default UploadProfile;
