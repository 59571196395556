import { xNFTs } from "../../http/config";

export const putOnFixPriceApi = async (payload) => {
  try {
    const res = await xNFTs.post("putOnFixedPrice", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
// Listing On Auction

export const putOnAuctionApi = async (payload) => {
  try {
    const res = await xNFTs.post("listOnAuction", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
