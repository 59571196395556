/** @format */

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Paper, Skeleton, Typography } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { profileBaseURL } from "../../http/config";

const SearchPlace = ({ setClose, close }) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const {
    searchData: { nft, creators },
    isSearchParamsLoading,
    isSearchParamsSuccess,
  } = useSelector((state) => state.auth);

  return (
    <Paper marginTop={2} elevation={5} className={classes.paperContainer}>
      {!isSearchParamsSuccess ? (
        ""
      ) : (
        <>
          <>
            {creators?.length > 0 ? (
              <div>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      paddingLeft: "8px",
                    }}
                  >
                    Creators
                  </Typography>
                  {creators?.map(({ nft_id, username, img, walletAddress }) => (
                    <Box
                      key={nft_id}
                      className={classes.nftContainer}
                      onClick={() => {
                        navigate(`/userprofile/${walletAddress}`);
                        setClose(!close);
                      }}
                    >
                      <Box margin={1}>
                        {isSearchParamsLoading ? (
                          <Skeleton variant="square">
                            <Avatar />
                          </Skeleton>
                        ) : (
                          <Avatar src={profileBaseURL + img} />
                        )}
                      </Box>
                      <Box width="100%">
                        {isSearchParamsLoading ? (
                          <Skeleton width="100%">
                            <Typography>.</Typography>
                          </Skeleton>
                        ) : (
                          <Typography className={classes.title}>
                            {username}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </div>
            ) : (
              <div>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography>No Result Found!</Typography>
                </Box>
              </div>
            )}
          </>
          <>
            {nft?.length > 0 && (
              <div>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      paddingLeft: "8px",
                    }}
                  >
                    NFT's
                  </Typography>
                  {nft?.map(
                    ({ nft_id, title, image, tokenId, chainId, amount }) => (
                      <Box
                        key={nft_id}
                        className={classes.nftContainer}
                        onClick={() => {
                          navigate(`/${tokenId}/${amount}/${chainId}`);
                          setClose(!close);
                        }}
                      >
                        <Box margin={1}>
                          {isSearchParamsLoading ? (
                            <Skeleton variant="square">
                              <Avatar />
                            </Skeleton>
                          ) : (
                            <img
                              src={image}
                              width={"40px"}
                              height={"40px"}
                              style={{ borderRadius: "5px" }}
                              alt=""
                            />
                          )}
                        </Box>
                        <Box width="100%">
                          {isSearchParamsLoading ? (
                            <Skeleton width="100%">
                              <Typography>.</Typography>
                            </Skeleton>
                          ) : (
                            <Typography className={classes.title}>
                              {title}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              </div>
            )}
          </>
        </>
      )}
    </Paper>
  );
};

export default SearchPlace;
const useStyle = makeStyles((theme) => ({
  title: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  paperContainer: {
    minWidth: "100%",
    maxWidth: "100%",
    maxHeight: "250px",
    overflow: "auto",
    padding: "10px",
    position: "absolute",
  },
  creatorContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  nftContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
  },
}));
