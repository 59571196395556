import { Box, CircularProgress, InputBase, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Button from "../button";
import * as Yup from "yup";
import {
  useContract,
  useAddress,
  useContractWrite,
  useContractRead,
  useCreateAuctionListing,
  useChainId,
} from "@thirdweb-dev/react";

import { useDispatch, useSelector } from "react-redux";
import { putOnAuction } from "../../redux/listing/listing.action";
const AuctionForm = ({
  tokenId,
  chainId,
  ownerWallet,
  onSale,
  pieceId,
  auctionId,
  amountId,
  reservePrice,
}) => {
  const {
    marketplaceContract,
    marketplaceType,
    collectionContract,
    collectionType,
  } = useSelector((state) => state.mint);
  const dispatch = useDispatch();
  const wallet = useAddress();
  const currentChain = useChainId();
  const classes = useStyles();
  const { contract: marketplace } = useContract(
    marketplaceContract,
    marketplaceType
  );
  const { contract: nftCollection } = useContract(
    collectionContract,
    collectionType
  );
  const { data } = useContractRead(nftCollection, "isApprovedForAll", [
    wallet,
    marketplaceContract,
  ]);

  const { mutateAsync: setApprovalForAll } = useContractWrite(
    nftCollection,
    "setApprovalForAll"
  );
  const call = async () => {
    try {
      const data = await setApprovalForAll({
        args: [marketplaceContract, true],
      });
      console.info("contract call successs", data);
    } catch (err) {
      console.error("contract call failure", err);
    }
  };

  const {
    mutateAsync: createAuctionListing,

    isLoading,
  } = useCreateAuctionListing(marketplace);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      auctionPrice: "",

      //add transferHash
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      if (!data) {
        call();
      }
      const date = new Date(Date.now());

      date.setDate(date.getDate() + 1);

      const txResult = await createAuctionListing({
        assetContractAddress: collectionContract,
        tokenId: tokenId,
        buyoutBidAmount: 5,
        minimumBidAmount: values.auctionPrice,
        startTimestamp: new Date(),
        endTimestamp: date,
        overrides: {
          overrides: { gasLimit: 2000000000000000000000 },
        },
      });
      const txh = txResult.receipt.transactionHash;
      const orderId = parseInt(txResult.id, 16);
      console.log(txh, "txh");
      console.log(orderId, "orderId");
      //api for addBidding

      const payload = {
        auctionId: orderId,
        amountId: 1,
        tokenId: tokenId,
        transactionHash: txh,
        ownerWallet: wallet,
        reservePrice: values.auctionPrice,
        chainId: chainId,
      };
      dispatch(putOnAuction(payload));
    },
  });
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {" "}
        <Typography
          pb={2}
          sx={{ color: "#fff", fontSize: "20px", fontWeight: 600 }}
        >
          Bid a Price
        </Typography>
        <ErrorOutlineIcon sx={{ color: "white", fontSize: "30px" }} />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <InputBase
            sx={{ backgroundColor: "#2A203E !important" }}
            placeholder="eg. Place your Bid"
            className={classes.nameInput}
            name="auctionPrice"
            type="float"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.auctionPrice && formik.errors.auctionPrice ? (
            <Typography style={{ color: "red", fontSize: "14px", pl: 2 }}>
              {formik.errors.auctionPrice}
            </Typography>
          ) : null}
        </Box>
        <Box pt={4}>
          {chainId === currentChain ? (
            <>
              {onSale === 0 ? (
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    borderRadius: "2px !important",
                    background: "rgba(120, 121, 241, 0.33)",
                    border: "1.5px solid #7879F1",
                    padding: "10px 40px !important",
                    width: "100% !important",
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={"16px"} sx={{ color: "#fff" }} />
                  ) : (
                    "Put On Auction"
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "2px !important",
                    background: "rgba(120, 121, 241, 0.33)",
                    border: "1.5px solid #7879F1",
                    padding: "10px 40px !important",
                    width: "100% !important",
                  }}
                >
                  Not for Sale
                </Button>
              )}
            </>
          ) : (
            <Typography sx={{ color: "white", fontSize: "18px" }}>
              To put on Auction, Change your chain to{" "}
              <span style={{ color: "red" }}>
                {chainId === 137
                  ? "MATIC"
                  : chainId === 1
                  ? "ETH"
                  : chainId === 8453
                  ? "BASE"
                  : "AVAL"}
              </span>
            </Typography>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default AuctionForm;
const useStyles = makeStyles((theme) => ({
  nameInput: {
    color: "#7C7C7C !important",
    fontSize: "18px !important",
    fontWeight: 400 + "!important",
    padding: 10,
    borderRadius: "10px !important",
    width: "100% !important",
  },
}));
const validationSchema = Yup.object().shape({
  auctionPrice: Yup.string().required("Please enter a bid price"),
});
