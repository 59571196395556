import { createAsyncThunk } from "@reduxjs/toolkit";
import { putOnAuctionApi, putOnFixPriceApi } from "./api";

export const putOnFixedPrice = createAsyncThunk(
  "listing/putOnFixedPrice",
  async (payload) => {
    try {
      const res = await putOnFixPriceApi(payload);
      return res.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

////////////////////////////////////////////////////////////////
export const putOnAuction = createAsyncThunk(
  "listing/putOnAuction",
  async (payload) => {
    try {
      const res = await putOnAuctionApi(payload);
      return res.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
