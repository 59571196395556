import icon1 from "../../../../assets/icons/SVG (1).png";
import icon2 from "../../../../assets/icons/SVG (2).png";
import icon3 from "../../../../assets/icons/SVG (3).png";
import icon4 from "../../../../assets/icons/SVG (4).png";
import icon5 from "../../../../assets/icons/SVG (5).png";

export const tabCollectionData = [
  { id: 32, icon: icon1, alt: "Tab 1" },
  { id: 33, icon: icon2, alt: "Tab 2" },
  { id: 34, icon: icon3, alt: "Tab 3" },
  { id: 35, icon: icon4, alt: "Tab 4" },
  { id: 36, icon: icon5, alt: "Tab 5" },
];

export const tabTimeData = [
  { id: 1, label: "1h" },
  { id: 3, label: "1D" },
  { id: 4, label: "7D" },
  { id: 5, label: "30D" },
];
