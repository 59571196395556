import { useDispatch, useSelector } from "react-redux";
import SuccessAlert from "../alert";
import {
  resetCoverUpdate,
  resetProfileUpdate,
  resetUpdateInfo,
} from "../../redux/profile/profile.slice";
import { useEffect } from "react";
import { resetStateMint } from "../../redux/mint/mint.slicer";
import {
  resetAuction,
  resetFixPrice,
} from "../../redux/listing/listing.slicer";
import { resetAddBidding } from "../../redux/bid/bid.slicer";

const Notifications = ({ submitting, bidSubmit, fixPriceSubmit }) => {
  const dispatch = useDispatch();

  const {
    isUpdateInfo,
    isUpdateInfoLoading,
    isUpdateInfoFailed,
    infoUpdateSuccess,
    infoUpdateError,
    isProfileUpdated,
    isProfileUpdatedLoading,
    isProfileUpdatedFailed,
    profileMessage,
    profileError,
    isCoverUpdated,
    isCoverUpdatedLoading,
    isCoverUpdatedFailed,
    coverMessage,
    coverError,
  } = useSelector((state) => state.profile);
  const {
    isMintLoading,
    isMintSuccess,
    isMintFailed,
    mintSuccess,
    mintFailed,
  } = useSelector((state) => state.mint);
  const {
    isPutOnAuctionLoading,
    isPutOnAuctionSuccess,
    isPutOnAuctionFailed,
    errorMessage,
    successMessage,
    isPutOnFixPriceLoading,
    isPutOnFixPriceSuccess,
    isPutOnFixPriceFailed,
  } = useSelector((state) => state.putOnFixPrice);
  const {
    isBiddingSuccess,
    isBiddingLoading,
    isBiddingFailed,
    success,
    error,
  } = useSelector((state) => state.bidding);
  useEffect(() => {
    setTimeout(() => {
      if (isBiddingSuccess || isBiddingLoading || isBiddingFailed) {
        dispatch(resetAddBidding());
      }
    }, 2000);
  }, [dispatch, isBiddingSuccess, isBiddingLoading, isBiddingFailed]);
  useEffect(() => {
    setTimeout(() => {
      if (
        isPutOnAuctionLoading ||
        isPutOnAuctionSuccess ||
        isPutOnAuctionFailed
      ) {
        dispatch(resetAuction());
      }
    }, 3000);
  }, [
    dispatch,
    isPutOnAuctionLoading,
    isPutOnAuctionSuccess,
    isPutOnAuctionFailed,
  ]);
  useEffect(() => {
    setTimeout(() => {
      if (
        isPutOnFixPriceLoading ||
        isPutOnFixPriceSuccess ||
        isPutOnFixPriceFailed
      ) {
        dispatch(resetFixPrice());
      }
    }, 3000);
  }, [
    dispatch,
    isPutOnFixPriceLoading,
    isPutOnFixPriceSuccess,
    isPutOnFixPriceFailed,
  ]);
  useEffect(() => {
    setTimeout(() => {
      if (isMintLoading || isMintSuccess || isMintFailed) {
        dispatch(resetStateMint());
      }
    }, 3000);
  }, [dispatch, isMintLoading, isMintSuccess, isMintFailed]);
  useEffect(() => {
    setTimeout(() => {
      if (isUpdateInfo || isUpdateInfoLoading || infoUpdateSuccess) {
        dispatch(resetUpdateInfo());
      }
    }, 4000);
  }, [isUpdateInfo, isUpdateInfoLoading, infoUpdateSuccess, dispatch]);
  setTimeout(() => {
    if (isProfileUpdated || isProfileUpdatedLoading || isProfileUpdatedFailed) {
      dispatch(resetProfileUpdate());
    }
  }, 4000);
  setTimeout(() => {
    if (isCoverUpdated || isCoverUpdatedLoading || isCoverUpdatedFailed) {
      dispatch(resetCoverUpdate());
    }
  }, 4000);
  return (
    <>
      {/* UPDATE INFO */}
      {(isUpdateInfo || isUpdateInfoLoading || isUpdateInfoFailed) && (
        <SuccessAlert
          loading={isUpdateInfoLoading}
          success={isUpdateInfo}
          error={isUpdateInfoFailed}
          message={isUpdateInfo ? infoUpdateSuccess : infoUpdateError}
        />
      )}
      {/* UPDATE PROFILE */}
      {(isProfileUpdated ||
        isProfileUpdatedLoading ||
        isProfileUpdatedFailed) && (
        <SuccessAlert
          loading={isProfileUpdatedLoading}
          success={isProfileUpdated}
          error={isProfileUpdatedFailed}
          message={isProfileUpdated ? profileMessage : profileError}
        />
      )}
      {/* UPDATE COVER */}
      {(isCoverUpdated || isCoverUpdatedLoading || isCoverUpdatedFailed) && (
        <SuccessAlert
          loading={isCoverUpdatedLoading}
          success={isCoverUpdated}
          error={isCoverUpdatedFailed}
          message={isCoverUpdated ? coverMessage : coverError}
        />
      )}
      {/* Mint  */}
      {(isMintLoading || isMintSuccess || isMintFailed || submitting) && (
        <SuccessAlert
          loading={isMintLoading || submitting}
          success={isMintSuccess}
          error={isMintFailed}
          message={isMintSuccess ? mintSuccess : mintFailed}
        />
      )}
      {/* Auction Loader  */}

      {(isPutOnAuctionLoading ||
        isPutOnAuctionSuccess ||
        isPutOnAuctionFailed) && (
        <SuccessAlert
          loading={isPutOnAuctionLoading}
          success={isPutOnAuctionSuccess}
          error={isPutOnAuctionFailed}
          message={isPutOnAuctionSuccess ? successMessage : errorMessage}
        />
      )}
      {/* fix Price Loader  */}
      {(isPutOnFixPriceLoading ||
        isPutOnFixPriceSuccess ||
        isPutOnFixPriceFailed) && (
        <SuccessAlert
          loading={isPutOnFixPriceLoading || fixPriceSubmit}
          success={isPutOnFixPriceSuccess}
          error={isPutOnFixPriceFailed}
          message={isPutOnFixPriceSuccess ? successMessage : errorMessage}
        />
      )}
      {/* Bidding  */}
      {(isBiddingSuccess || isBiddingLoading || isBiddingFailed) && (
        <SuccessAlert
          loading={isBiddingLoading || bidSubmit}
          success={isBiddingSuccess}
          error={isBiddingFailed}
          message={isBiddingSuccess ? success : error}
        />
      )}
    </>
  );
};

export default Notifications;
