import { Box } from "@mui/material";

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        paddingLeft: { lg: 2.6, md: 2.6, sm: 2.6, xs: 2.6 },
        paddingRight: { lg: 2.6, md: 2.6, sm: 2.6, xs: 2.6 },
      }}
    >
      {children}
    </Box>
  );
};
export default Wrapper;
