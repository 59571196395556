import { xNFTs } from "../../http/config";

export const addBiddingApi = async (payload) => {
  try {
    const res = await xNFTs.post("addBidding", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
