import React from "react";
import PropTypes from "prop-types";

const MenuIcon = ({ color, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      //   style={{ fill: "rgba(227, 255, 0, 0.51)", filter: "blur(324.75px)" }}
    >
      <rect width="22" height="2" rx="1" fill={color} />
      <rect y="10" width="28" height="2" rx="1" fill={color} />
      <rect y="20" width="22" height="2" rx="1" fill={color} />
    </svg>
  );
};

MenuIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

MenuIcon.defaultProps = {
  color: "#00FF00", // Green color
  width: 100,
  height: 24,
};

export default MenuIcon;
