import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    color: "#fff",
    display: "grid",
    gridTemplateColumns: "1fr 2fr ",
    padding: "15px 20px 40px 20px",
    backgroundColor: "var(--PRIMARY-BLACK, #0F0B33)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gap: 30,
      padding: "0px 20px 10px 20px",
    },
  },
  rightContainer: {
    display: "flex",
    justifyContent: "space-around",
    gap: 30,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  description: {
    fontSize: "16px",
    fontWeight: 400 + "!important",
    lineHeight: "32px",
    color: "#ADB9C7 !important",
    cursor: "pointer",
    textDecoration: "none !important",
  },
  image: {
    width: "123px",
    objectFit: "contain",
  },
  heading: {
    fontSize: "24px",
    fontWeight: 700 + "!important",
    lineHeight: "32px",
  },
  linksContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  icon: {
    background: "#fff",
    color: "#000",
    borderRadius: "5px",
    fontSize: "32px",
    padding: 2,
  },
}));
