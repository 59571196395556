import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TableBodyData, tableHeadData } from "../TableMain/tableData";
import CardCollectionTabs from "../TableTabs/cardCollectionTabs";
const tabContents = [
  { tableColumnData: tableHeadData, tableRowsData: TableBodyData },
  { tableColumnData: tableHeadData, tableRowsData: TableBodyData },
  { tableColumnData: tableHeadData, tableRowsData: TableBodyData },
  { tableColumnData: tableHeadData, tableRowsData: TableBodyData },
  { tableColumnData: tableHeadData, tableRowsData: TableBodyData },
];

export const TableContainer = () => {
  const classes = useStyles();

  return (
    <Stack gap={4}>
      <Typography
        variant="h6"
        sx={{
          color: "white",
          fontSize: { lg: "36px", md: "32px", sm: "27px" },
          fontWeight: 700,
          width: "fit-content",
        }}
      >
        Chains Market Place
      </Typography>

      <Paper variant="outlined" className={classes.tableContainer}>
        <Typography variant="h6" className={classes.tableCaption}>
          Chains Market Place
        </Typography>

        <Stack direction="row" gap={2}>
          <CardCollectionTabs tabContents={tabContents} />
        </Stack>

        {/* <Button
          endIcon={<NavigateNextIcon />}
          variant="contained"
          fullWidth
          className={classes.tableButton}
        >
          View all collections
        </Button> */}
      </Paper>
    </Stack>
  );
};

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: "#FFFFFF14 !important",
    borderColor: "#FFFFFF60 !important",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },

  tableCaption: {
    color: "#FFFFFF",
    borderBottom: "1px solid #FFFFFF63",
    paddingBottom: "0.5rem",
  },

  tableButton: {
    backgroundColor: "#FFFFFF20 !important",
    color: "white !important",
    borderRadius: "0.5rem !important",
    "&:hover": {
      backgroundColor: "#FFFFFF40!important",
    },
  },
}));
