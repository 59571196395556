import { createSlice } from "@reduxjs/toolkit";
import { auctionTransfer, directTransfer } from "./transfer.action";

const initialState = {
  isDirectTransferLoading: false,
  isDirectTransferSuccess: false,
  isDirectTransferFailed: false,
  successMessage: {},
  errorMessage: {},
  ///////////////////////////////

  isAuctionTransferLoading: false,
  isAuctionTransferSuccess: false,
  isAuctionTransferFailed: false,
};

const directTransferSlicer = createSlice({
  name: "transfer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(directTransfer.pending, (state) => {
      state.isDirectTransferLoading = true;
    });
    builder.addCase(directTransfer.fulfilled, (state, action) => {
      state.isDirectTransferLoading = false;
      state.isDirectTransferSuccess = true;
      state.successMessage = action.payload;
    });
    builder.addCase(directTransfer.rejected, (state, action) => {
      state.isDirectTransferLoading = false;
      state.isDirectTransferSuccess = false;
      state.isDirectTransferFailed = true;
      state.errorMessage = action.error.message;
    });
    ////////////////////////////////////////////////
    builder.addCase(auctionTransfer.pending, (state) => {
      state.isAuctionTransferLoading = true;
    });
    builder.addCase(auctionTransfer.fulfilled, (state, action) => {
      state.isAuctionTransferLoading = false;
      state.isAuctionTransferSuccess = true;
      state.successMessage = action.payload;
    });
    builder.addCase(auctionTransfer.rejected, (state, action) => {
      state.isAuctionTransferLoading = false;
      state.isAuctionTransferSuccess = false;
      state.isAuctionTransferFailed = true;
      state.errorMessage = action.error.message;
    });
  },
});

export default directTransferSlicer.reducer;
