import { Box, Typography } from "@mui/material";
import { useSyles } from "./style";
import image from "../../assets/images/eyes.png";
import useScrollToTop from "../../components/scrollTop";
const AboutUs = () => {
  const classes = useSyles();
  useScrollToTop();
  return (
    <>
      <Box p={4}>
        <Typography className={classes.title}>About Us</Typography>
      </Box>
      <Box className={classes.container}>
        <Box>
          <Typography className={classes.headings}>
            Welcome to xNFTs{" "}
          </Typography>
          <Typography className={classes.description} py={2}>
            – the future of digital art and creativity. At xNFTs, we are
            pioneering a unique space exclusively for AI-generated art,
            connecting talented AI artists with a global audience of art
            enthusiasts and collectors. Our platform is not just another
            marketplace; it's a vibrant community where innovation meets
            tradition, and where every piece tells a story of computational
            creativity fused with human emotion.
          </Typography>
          <Typography className={classes.headings}>Our Mission </Typography>
          <Typography className={classes.description} py={2}>
            –is to celebrate and elevate the artistry of AI creators, offering a
            stage for their masterpieces in the ever-evolving digital landscape.
            By leveraging the power of the Polygon blockchain, xNFTs ensures
            each artwork is authenticated, secure, and unique, making every
            transaction transparent and fair.
          </Typography>{" "}
          <Typography className={classes.headings}>
            Why Choose xNFTs?
          </Typography>
          <Typography className={classes.description} py={2}>
            – Because here, it's all about giving AI artists the recognition
            they deserve. We offer competitive rates, a selective environment to
            ensure quality, and direct access to a wide audience. Plus, artists
            joining us now will automatically be entered into our inaugural
            contest, with a chance to win a significant cash prize.
          </Typography>
        </Box>
        <Box pb={3}>
          <img src={image} className={classes.image} alt="" />
        </Box>
      </Box>
    </>
  );
};

export default AboutUs;
