import React from "react";
import { Avatar, TableRow, Stack, Typography, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  currencyColor: {
    color: "#FFFFFF99",
    textTransform: "uppercase",
  },
  positiveValue: {
    color: "#28B833",
  },
  negativeValue: {
    color: "#E94949",
  },
  tableBodyCells: {
    color: "#EDEDED !important",
    borderBottomColor: "#FFFFFF63 !important",
    [theme.breakpoints.down("sm")]: {
      padding: "10px !important",
      "& .MuiTableCell-root ": {},
    },
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#FFFFFF20  !important",
    },
  },
  nftName: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
}));

const TableBodyRow = ({
  id,
  name,
  current_price,
  symbol,
  marketcap,
  volume,
  image,
  price_change_24h,
  total_volume,
}) => {
  const classes = useStyles();

  const renderTableCell = (value, align = "left") => (
    <TableCell align={align} className={classes.tableBodyCells}>
      {value}
    </TableCell>
  );

  const getTextColorClass = (value) => {
    const numericValue = parseFloat(value);
    return numericValue < 0 ? classes.negativeValue : classes.positiveValue;
  };

  return (
    <TableRow hover tabIndex={-1} key={id} className={classes.tableRow}>
      {renderTableCell(
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            sx={{
              width: { sm: 30, md: 40 },
              height: { sm: 30, md: 40 },
            }}
            variant="rounded"
            alt="/"
            src={image}
          />
          <Typography className={classes.nftName}>{name}</Typography>
        </Stack>,
        "left"
      )}
      {renderTableCell(
        <Stack>
          <Typography className={classes.currencyColor}>{symbol}</Typography>
        </Stack>,
        "left"
      )}
      {renderTableCell(
        <Typography>
          {" "}
          <span className={classes.currencyColor}>$</span>&nbsp;{current_price}
        </Typography>,
        "left"
      )}
      {renderTableCell(
        <Stack sx={{ display: "flex" }}>
          <Typography>
            <span className={classes.currencyColor}>$</span>&nbsp;
            {total_volume}
          </Typography>
        </Stack>,
        "left"
      )}
      {price_change_24h < 0 ? (
        <>
          {renderTableCell(
            <Typography className={getTextColorClass(price_change_24h)}>
              {price_change_24h.toFixed(2)}%
            </Typography>,
            "right"
          )}
        </>
      ) : (
        <>
          {" "}
          {renderTableCell(
            <Typography className={getTextColorClass(price_change_24h)}>
              {price_change_24h.toFixed(2)}%
            </Typography>,
            "right"
          )}{" "}
        </>
      )}

      {/* {renderTableCell(items, "right")}
      {renderTableCell(owners, "right")} */}
    </TableRow>
  );
};

export default TableBodyRow;
