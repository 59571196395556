import { createAsyncThunk } from "@reduxjs/toolkit";
import { mintApi } from "./api";

export const mint = createAsyncThunk("mint", async (payload) => {
  try {
    const res = await mintApi(payload);
    return res.data;
  } catch (error) {
    throw error.response.data.message;
  }
});
export const changeNetwork = createAsyncThunk(
  "mint/changeNetwork",
  async (payload) => {
    try {
      return payload;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
export const changeCollectionContract = createAsyncThunk(
  "mint/changeCollectionContract",
  async (payload) => {
    try {
      console.log(payload, "payload");
      return payload;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
export const changeMarketplaceContract = createAsyncThunk(
  "mint/changeMarketplaceContract",
  async (payload) => {
    try {
      console.log(payload, "payload");
      return payload;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
