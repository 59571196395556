import { xNFTs } from "../../http/config";

export const loginApi = async (payload) => {
  try {
    const res = await xNFTs.post("login", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const searchUserApi = async (payload) => {
  try {
    const res = await xNFTs.get(`search?name=${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};
