/** @format */

import { Box } from "@mui/material";
import React from "react";
import Alert from "@mui/material/Alert";

import "./style.css";
import CloseIcon from "@mui/icons-material/Close";
import successGif from "../../assets/gifs/success.gif";
import loadingGif from "../../assets/gifs/loading.gif";
import errorGif from "../../assets/gifs/error.gif";

const SuccessAlert = ({ message, loading, success, error }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "5%",
        padding: "8px 10px 8px 10px",
        borderRadius: "20px",
        right: "-100%",
        // width: "25%",
        backgroundColor: "#3B324E",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        zIndex: 1000,
      }}
      className="animated-toaster"
    >
      <Box
        sx={{
          borderRight: "2px solid #8C869C",
          marginRight: "5px",
          width: "100%",
        }}
      >
        <Alert
          sx={{
            color: "white",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#3B324E",
            justifyContent: "space-between",
            opacity: 1,
          }}
          icon={
            loading ? (
              <img src={loadingGif} width={"30px"} alt="" />
            ) : success ? (
              <img src={successGif} width={"27px"} alt="" />
            ) : error ? (
              <img src={errorGif} width={"30px"} alt="" />
            ) : null
          }
        >
          {loading ? "Loading Please Wait" : message}
        </Alert>
      </Box>
      <CloseIcon style={{ color: "white", fontSize: "20px" }} />
    </Box>
  );
};

export default SuccessAlert;
