import MapCard from "../../components/mapCard";
import RoadMapEffect from "../../components/roadmap";
import { Box } from "@mui/material";
import useScrollToTop from "../../components/scrollTop";
const RoadMap = () => {
  useScrollToTop();
  return (
    <>
      <Box sx={{}}>
        <Box
          sx={{
            padding: "30px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <RoadMapEffect />
        </Box>

        <Box>
          <MapCard />;
        </Box>
      </Box>
    </>
  );
};
export default RoadMap;
