import { Typography, Box } from "@mui/material";

const RoadMapEffect = () => {
  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography
          variant="h1"
          style={{
            backgroundColor: "#5351A9",
            backgroundClip: "text",
            WebkitTextStrokeColor: "transparent",
            WebkitTextStrokeWidth: "calc(1em /20)",
            WebkitBackgroundClip: "text",
            color: "#13082A",
            fontSize: "70px",
            fontWeight: 700,
            lineHeight: "127.37px",
          }}
        >
          ROAD
        </Typography>
        <svg
          width="60"
          height="60"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M39.413 29.0105L39.8559 29.8969L40.845 29.8373L64.6966 28.4001L43.3219 39.0812L42.4355 39.5241L42.4951 40.5132L43.9323 64.3648L33.2512 42.9901L32.8083 42.1037L31.8192 42.1633L7.96762 43.6006L29.3423 32.9194L30.2287 32.4765L30.1691 31.4874L28.7319 7.63584L39.413 29.0105Z"
            stroke="#6D6DDC"
            stroke-width="3"
          />
        </svg>
        <Typography
          variant="h1"
          style={{
            backgroundColor: "#5351A9",
            backgroundClip: "text",
            WebkitTextStrokeColor: "transparent",
            WebkitTextStrokeWidth: "calc(1em /20)",
            WebkitBackgroundClip: "text",
            color: "#13082A",
            fontSize: "70px",
            fontWeight: 700,
            lineHeight: "127.37px",
          }}
        >
          MAP
        </Typography>
      </Box>
    </>
  );
};
export default RoadMapEffect;
