import React from "react";

import { Stack, Box } from "@mui/material";
import DynamicTabBar from "../tabsBar";
import CardCollectionPanel from "../cardCollectionPanel";
import { tabCollectionData, tabTimeData } from "./tabData";

const CardCollectionTabs = ({ tabContents }) => {
  const [tabValueTime, setTabValueTime] = React.useState(0);
  const [tabValueCollection, setTabValueCollection] = React.useState(0);

  const handleChangeTime = (event, newValue) => {
    setTabValueTime(newValue);
  };

  const handleChangeCollection = (event, newValue) => {
    setTabValueCollection(newValue);
  };

  return (
    <Stack gap={2} sx={{ width: "100%" }}>
      <Stack direction="row" gap={2}>
        <DynamicTabBar
          tabData={tabTimeData}
          onHandleChange={handleChangeTime}
          tabValue={tabValueTime}
        />
        <DynamicTabBar
          tabData={tabCollectionData}
          onHandleChange={handleChangeCollection}
          tabValue={tabValueCollection}
        />
      </Stack>

      <Box>
        {tabCollectionData.map((_, index) => (
          <CardCollectionPanel
            key={index}
            index={index}
            value={tabValueCollection}
            tabContents={tabContents}
          />
        ))}
      </Box>
    </Stack>
  );
};

export default CardCollectionTabs;
