import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import Logo from "../../assets/logo/nftLogo.png";
import MenuIcon from "../menuIcon/index";
import { makeStyles } from "@mui/styles";
import search from "../../assets/icons/search.svg";
import { useNavigate } from "react-router-dom";
import { ConnectWallet, useAddress } from "@thirdweb-dev/react";

const HamBurger = ({ toggleDrawer, handleOpen }) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  const wallet = useAddress();
  function handleHome() {
    navigate("/");
    setOpen(!true);
  }
  function handleCreate() {
    navigate("/create");
    setOpen(!true);
  }
  function handleBrowse() {
    navigate("/browse");
    setOpen(!true);
  }
  function handleMyWallet() {
    navigate(`/userprofile/${wallet}`);
    setOpen(!true);
  }
  // function handleCart() {
  //   setOpen(!true); // Close the current drawer
  //   toggleDrawer(true); // Open the cart drawer
  // }

  return (
    <Box sx={{ display: { lg: "none", md: "none", sm: "block", xs: "block" } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <img src={Logo} width="70px" alt="" />
        </Box>
        <Box>
          <IconButton
            edge="start"
            fontSize="large"
            onClick={() => {
              setOpen(true);
            }}
          >
            <MenuIcon color="rgba(120, 121, 241, 1)" width={55} height={30} />
          </IconButton>
        </Box>
      </Box>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {}}
        className={classes.swipeableDrawer}
      >
        <Box p={2}>
          <Box pb={2} onClick={handleHome} className={classes.container}>
            {" "}
            <img src={Logo} width="70px" alt="" />
            <CancelIcon
              onClick={() => setOpen(false)}
              sx={{ color: "#7779F0" }}
            />
          </Box>
          <hr />
          <Box display="flex" flexDirection="column" gap="20px" pt={2}>
            <ListItem disablePadding>
              <ConnectWallet />
            </ListItem>
            <ListItem
              disablePadding
              onClick={handleCreate}
              className={classes.listItem}
            >
              <ListItemText primary="Creator" />
            </ListItem>
            <ListItem
              onClick={handleBrowse}
              disablePadding
              className={classes.listItem}
            >
              <ListItemText primary="Browse" />
            </ListItem>
            <ListItem
              onClick={handleMyWallet}
              disablePadding
              className={classes.listItem}
            >
              <ListItemText primary="My Wallet" />
            </ListItem>
            <ListItem disablePadding>
              <img src={search} alt="" width="25px" />
            </ListItem>
            {/* <ListItem sx={{ padding: "0" }}>
              <IconButton
                sx={{
                  padding: "0 !important",
                  marginRight: "10px !important",
                }}
                onClick={() => {
                  handleCart();
                }}
              >
                <ShoppingCartIcon sx={{ color: "#fff", fontSize: "30px" }} />
                <span className={classes.span}>1</span>
              </IconButton>
            </ListItem> */}
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};
export default HamBurger;
const useStyle = makeStyles((theme) => ({
  swipeableDrawer: {
    // background: "black",
    "& .MuiPaper-root": {
      background: "#000",
      width: "100%",
      color: "white",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      borderBottom: "1px solid #7779F0",
    },
  },
  span: {
    backgroundColor: "#F00",
    borderRadius: "50% !important",
    position: "absolute",
    fontSize: "10px",
    color: "#fff",
    padding: "3px",
    bottom: "17px",
    right: "0px",
    fontWeight: "bold",
  },
}));
