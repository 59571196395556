import { Box, Button, InputBase, Typography } from "@mui/material";

const DeleteUser = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          width: { lg: "50%", md: "50%", sm: "100%", xs: "100%" },
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        {" "}
        <Typography>Email</Typography>
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 0.60)",
          }}
        >
          Your email for marketplace notifications
        </Typography>
        <InputBase
          placeholder="@gmail.com"
          name="email"
          // onChange={formik.handleChange}
          sx={{
            border: "",
            background: "#313131",
            padding: "5px 20px",
            width: "100%",
            borderRadius: "10px",
            color: "rgba(255, 255, 255, 0.40)",
            fontSize: "14px",
            fontWeight: 400,
          }}
        />
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 0.60)",
          }}
        >
          Please check 6v9qf9m95p@secretmail.net and verify your email address.
          Still no email?<span>Resend</span>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "5px", flexDirection: "column" }}>
        <Box>
          {" "}
          <Typography>Danger zone</Typography>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 500,
              color: "rgba(255, 255, 255, 0.60)",
            }}
          >
            Once you delete your account, there is no going back. Please be
            certain.
          </Typography>
        </Box>
        <Box>
          {" "}
          <Button
            sx={{
              color: "#E94949",
              background: " rgba(233, 73, 73, 0.10) !important",
              textTransform: "initial",
              padding: "10px 20px 10px 20px",
              borderRadius: "10px",
            }}
          >
            Delete my account
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default DeleteUser;
