import React from "react";
import { makeStyles } from "@mui/styles";
import { TableCell } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  tableHeading: {
    backgroundColor: "#FFFFFF14 !important",
    color: "#FFFFFF99 !important",
    textTransform: "capitalize",
    borderBottom: "none !important",

    [theme.breakpoints.down("sm")]: {
      fontSize: "6px !important",
      padding: "10px  !important",
    },
  },
}));

const TableHeadRow = ({ id = 1, label = "head", align = "left" }) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.tableHeading} key={id} align={align}>
      {label}
    </TableCell>
  );
};

export default TableHeadRow;
