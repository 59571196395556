import { Box, Button, TextField } from "@mui/material";
import { useGrantRole, useContract } from "@thirdweb-dev/react";
import WalletTable from "../creatorsTable";
import { useSelector } from "react-redux";
import { useState } from "react";

const MintingRole = ({ data, type }) => {
  const { collectionContract } = useSelector((state) => state.mint);
  const [walletAddress, setWalletAddress] = useState(null);
  const { contract } = useContract(collectionContract);
  const { mutateAsync: grantRole } = useGrantRole(contract);
  const {
    creators: { result },
  } = useSelector((state) => state.profile);
  const handleApprove = async (wallet) => {
    try {
      const tx = await grantRole({
        role: "minter", // name of your role.
        address: wallet, // address to grant the role to.
      });
      console.log(tx, "txxxx");
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleAdmin = async () => {
    try {
      if (walletAddress === null || walletAddress === "") {
        return window.alert("Enter the Wallet Address");
      } else {
        const tx = await grantRole({
          role: "admin", // name of your role.
          address: walletAddress, // address to grant the role to.
        });
        console.log(tx, "txxxx");
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const setWallet = (wallet) => {
    setWalletAddress(wallet);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
      }}
    >
      {type === "approvals" ? (
        <WalletTable
          data={result}
          minters={data}
          handleApprove={handleApprove}
        />
      ) : (
        <Box sx={{ color: "#fff", width: "100%", display: "block" }}>
          <Box>
            {" "}
            <h2>Add Admin Wallet</h2>{" "}
          </Box>
          <Box>
            <TextField
              sx={{ marginY: "10px", width: "40%", color: "white" }}
              name="wallet"
              id="outlined-basic"
              label="WalletAddress"
              variant="outlined"
              required
              focused
              onChange={(e) => {
                setWallet(e.target.value);
              }}
            />
          </Box>

          <Button
            onClick={handleAdmin}
            sx={{ width: "40%" }}
            size="large"
            variant="outlined"
          >
            Grant Role
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MintingRole;
