import { createSlice } from "@reduxjs/toolkit";
import { addBidding } from "./bid.action";

const initialState = {
  isBiddingSuccess: false,
  isBiddingLoading: false,
  isBiddingFailed: false,
  success: {},
  error: {},
};

const biddingSlicer = createSlice({
  name: "bidding",
  initialState: initialState,
  reducers: {
    resetAddBidding: (state) => {
      state.isBiddingLoading = false;
      state.isBiddingSuccess = false;
      state.isBiddingFailed = false;
      state.success = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addBidding.pending, (state) => {
      state.isBiddingLoading = true;
    });
    builder.addCase(addBidding.fulfilled, (state, action) => {
      state.isBiddingLoading = false;
      state.isBiddingSuccess = true;
      state.success = action.payload.message;
    });
    builder.addCase(addBidding.rejected, (state, action) => {
      state.isBiddingLoading = false;
      state.isBiddingSuccess = false;
      state.isBiddingFailed = true;
      state.error = action.error.message;
    });
  },
});

export default biddingSlicer.reducer;
export const { resetAddBidding } = biddingSlicer.actions;
