import { createAsyncThunk } from "@reduxjs/toolkit";
import { auctionTransferApi, directTransferApi } from "./api";

export const directTransfer = createAsyncThunk(
  "transfer/directTransfer",
  async (payload) => {
    try {
      const res = await directTransferApi(payload);
      return res.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
/////////////////////////////////////////////////

export const auctionTransfer = createAsyncThunk(
  "transfer/auctionTransfer",
  async (payload) => {
    try {
      const res = await auctionTransferApi(payload);
      return res.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
