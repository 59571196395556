import { Box } from "@mui/material";

import React from "react";
import { CustomTabs, Tabs } from "../editCustomTabs";
import EditCoverImage from "../editCover";
import { useFormik } from "formik";
import UserInformation from "../editUserInformation";
import DeleteUser from "../deleteUser";
import UploadProfile from "../uploadProfile";
import EditTabPanel from "../editTabPanel";
import { useDispatch, useSelector } from "react-redux";
import { updateInfo } from "../../../redux/profile/profile.action";

const EditprofileTabs = () => {
  const dispatch = useDispatch();
  const {
    user: {
      walletAddress,
      firstName,
      username,
      email,
      bio,
      instagram,
      twitter,
    },
  } = useSelector((state) => state.auth);
  console.log(firstName);
  const [value, setValue] = React.useState(0);
  const tabs = ["Profile", "Account"];
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      walletAddress: walletAddress,
      username: username,
      email: email,
      displayName: firstName,
      bio: bio,
      instagram: instagram,
      twitter: twitter,
    },
    onSubmit: (values) => {
      dispatch(updateInfo(values));
    },
  });

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { lg: "1fr 4fr", md: "1fr 5fr", sm: "1fr" },
      }}
    >
      <Tabs value={value} onChange={handleChange}>
        {tabs.map((items) => (
          <CustomTabs label={items} />
        ))}
      </Tabs>

      <Box>
        <EditTabPanel value={value} index={0}>
          <Box position={"relative"}>
            <Box>
              <EditCoverImage
                formik={formik}
                value={formik.values.coverImage}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "-15%", // Adjust as needed
                left: "2%", // Adjust as needed
              }}
            >
              <UploadProfile />
            </Box>
          </Box>
          <Box pt={5}>
            <UserInformation formik={formik} />
          </Box>
        </EditTabPanel>
        <EditTabPanel value={value} index={1}>
          <Box
            sx={{
              minHeight: "70vh",
              padding: {
                lg: "20px 30px",
                md: "20px 30px",
                sm: "10px 10px",
                xs: "10px 10px",
              },
            }}
          >
            <DeleteUser />
          </Box>
        </EditTabPanel>
      </Box>
    </Box>
  );
};

export default EditprofileTabs;
