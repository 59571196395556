import { combineReducers } from "@reduxjs/toolkit";
import authSlicer from "../redux/auth/auth.slicer";
import profileSlice from "../redux/profile/profile.slice";
import artsSlice from "../redux/arts/arts.slice";
import putOnFixedPriceSlicer from "../redux/listing/listing.slicer";
import directTransferSlicer from "../redux/transfer/transfer.slicer";
import mintSlicer from "../redux/mint/mint.slicer";
import biddingSlicer from "../redux/bid/bid.slicer";
import { selectReducer } from "../redux/chain/chain.slicer";
const rootReducer = combineReducers({
  auth: authSlicer,
  mint: mintSlicer,
  profile: profileSlice,
  arts: artsSlice,
  putOnFixPrice: putOnFixedPriceSlicer,
  directTransfer: directTransferSlicer,
  bidding: biddingSlicer,
  select: selectReducer,
});

export default rootReducer;
