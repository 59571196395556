import { Box, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useStyles } from "./style";
import BrowseFilters from "../../components/browseFilters";
import SingleCard from "../../components/card/singleCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllArts } from "../../redux/arts/arts.action";
import loader from "../../assets/gifs/loading.gif";
import useScrollToTop from "../../components/scrollTop";

const Browse = () => {
  const dispatch = useDispatch();
  useScrollToTop();
  const { AllArts, isAllArtsLoading } = useSelector((state) => state.arts);
  const [sortedData, setSortedData] = useState(AllArts);
  const classes = useStyles();
  useEffect(() => {
    dispatch(getAllArts());
  }, [dispatch]);
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.filtersContainer}>
        {" "}
        <Box>
          <Typography color={"#fff"} variant="h3">
            All Networks
          </Typography>
        </Box>
        <Box>
          {" "}
          <Box
            sx={{ backgroundColor: "#2D2342", margin: "15px 0px" }}
            className={classes.container}
          >
            <BrowseFilters setSortedData={setSortedData} data={AllArts} />
          </Box>
          <Box className={classes.mobileFilter}>
            <FilterAltIcon
              sx={{
                color: "#E3FF00",
                background: " #484848",
                borderRadius: "5px",
                fontSize: "40px",
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box pt={3} className={classes.cardContainer}>
        {isAllArtsLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={loader} width="100px" alt="" />
          </Box>
        ) : (
          <>
            {sortedData === "" ? (
              <>
                {" "}
                {AllArts &&
                  AllArts.map((items) => {
                    return <SingleCard {...items} />;
                  })}
              </>
            ) : (
              <>
                {" "}
                {sortedData &&
                  sortedData.map((items) => {
                    return <SingleCard {...items} />;
                  })}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
export default Browse;
