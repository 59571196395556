import { createSlice } from "@reduxjs/toolkit";
import {
  mint,
  changeNetwork,
  changeCollectionContract,
  changeMarketplaceContract,
} from "./mint.action";
import { Polygon } from "@thirdweb-dev/chains";
const initialState = {
  isMintLoading: false,
  isMintSuccess: false,
  isMintFailed: false,
  mintSuccess: {},
  mintFailed: {},
  network: JSON.stringify(Polygon),
  collectionContract: "",
  collectionType: "nft-collection",
  marketplaceContract: "",
  marketplaceType: "marketplace-v3",
};

const mintSlicer = createSlice({
  name: "mint",
  initialState,
  reducers: {
    resetStateMint: (state) => {
      state.isMintLoading = false;
      state.isMintSuccess = false;
      state.isMintFailed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(mint.pending, (state) => {
      state.isMintLoading = true;
      state.isMintSuccess = false;
      state.isMintFailed = false;
    });
    builder.addCase(mint.fulfilled, (state, action) => {
      state.isMintLoading = false;
      state.isMintSuccess = true;
      state.isMintFailed = false;
      state.mintSuccess = action.payload.message;
    });
    builder.addCase(mint.rejected, (state, action) => {
      state.isMintLoading = false;
      state.isMintSuccess = false;
      state.isMintFailed = true;
      state.mintFailed = action.payload;
    });

    builder.addCase(changeNetwork.fulfilled, (state, action) => {
      state.network = action.payload;
    });
    builder.addCase(changeCollectionContract.fulfilled, (state, action) => {
      state.collectionContract = action.payload;
    });
    builder.addCase(changeMarketplaceContract.fulfilled, (state, action) => {
      state.marketplaceContract = action.payload;
    });

    ////////////////////////////////////////////////////////////////
  },
});

export default mintSlicer.reducer;
export const { resetStateMint } = mintSlicer.actions;
