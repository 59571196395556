import { Box } from "@mui/material";
import UserDetails from "../../components/userProfile/userDetails";
import {
  allCreators,
  singleCreator,
  inWallet as userCollection,
} from "../../redux/profile/profile.action";
import { useStyles } from "./style";
import ProfileTabs from "../../components/porfileTabs";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import loadingGIF from "../../assets/gifs/loading.gif";
import { useNavigate, useParams } from "react-router-dom";
import useScrollToTop from "../../components/scrollUp";

const UserProfile = () => {
  const { wallet } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  useScrollToTop();
  const {
    inWallet: { createdArts, collectedArts },
    isInWalletLoading,
  } = useSelector((state) => state.profile);

  const classes = useStyles();
  React.useEffect(() => {
    dispatch(singleCreator(wallet));
    dispatch(userCollection(wallet));
    dispatch(allCreators());
  }, [dispatch, wallet]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [navigate, isAuthenticated]);
  return (
    <Box className={classes.mainContainer}>
      <Box>
        <UserDetails />
      </Box>
      {isInWalletLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={loadingGIF} width={"10%"} alt="" />
        </Box>
      ) : (
        <Box py={3}>
          <ProfileTabs
            createdArts={createdArts}
            collectedArts={collectedArts}
          />
        </Box>
      )}
    </Box>
  );
};
export default UserProfile;
