import { createSlice } from "@reduxjs/toolkit";
import {
  allCreators,
  checkEmail,
  checkUsername,
  inWallet,
  singleCreator,
  updateInfo,
  uploadCover,
  uploadProfile,
} from "./profile.action";

const initialState = {
  isEmailExists: false,
  isEmailExistLoading: false,
  isEmailExistLoadingFailed: false,
  email: {},
  // username states
  isUsernameExists: false,
  isUsernameExistsLoading: false,
  isUsernameExistsFailed: false,
  username: {},
  // profile update
  isProfileUpdated: false,
  isProfileUpdatedLoading: false,
  isProfileUpdatedFailed: false,
  profileMessage: {},
  profileError: {},
  // cover image update
  isCoverUpdated: false,
  isCoverUpdatedLoading: false,
  isCoverUpdatedFailed: false,
  coverMessage: {},
  coverError: {},
  //update info
  isUpdateInfo: false,
  isUpdateInfoLoading: false,
  isUpdateInfoFailed: false,
  infoUpdateSuccess: [],
  infoUpdateError: [],
  //In wallet
  isInWalletLoading: false,
  isInWalletSuccess: false,
  inWallet: {},
  //all Creators
  isAllCreatorsLoading: false,
  isAllCreatorsSuccess: false,
  isAllCreatorsFailed: false,
  creators: [],
  //Single Creator
  isSingleCreatorLoading: false,
  isSingleCreatorFailed: false,
  singleCreator: {},
};

const profileSlicer = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    resetUpdateInfo: (state) => {
      state.isUpdateInfoLoading = false;
      state.isUpdateInfo = false;
      state.infoUpdateError = "";
      state.isUpdateInfoFailed = false;
    },
    resetProfileUpdate: (state) => {
      state.isProfileUpdatedLoading = false;
      state.isProfileUpdated = false;
      state.isProfileUpdatedFailed = false;
    },
    resetCoverUpdate: (state) => {
      state.isCoverUpdatedLoading = false;
      state.isCoverUpdated = false;

      state.isCoverUpdatedFailed = false;
    },
    resetUsernameCheck: (state) => {
      state.isUsernameExistsLoading = false;
      state.isUsernameExists = false;
      state.username = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkEmail.pending, (state) => {
      state.isEmailExistLoading = true;
    });
    builder.addCase(checkEmail.fulfilled, (state, action) => {
      state.isEmailExistLoading = false;
      state.isEmailExists = true;
      state.email = action.payload;
    });
    builder.addCase(checkEmail.rejected, (state) => {
      state.isEmailExistLoading = false;
      state.isEmailExists = false;
      state.isEmailExistLoadingFailed = true;
    });
    //Username
    builder.addCase(checkUsername.pending, (state) => {
      state.isUsernameExistsLoading = true;
    });
    builder.addCase(checkUsername.fulfilled, (state, action) => {
      state.isUsernameExistsLoading = false;
      state.isUsernameExists = true;
      state.username = action.payload;
    });
    builder.addCase(checkUsername.rejected, (state) => {
      state.isUsernameExistsLoading = false;
      state.isUsernameExists = false;
      state.isUsernameExistsFailed = true;
    });
    // this should be updating profile
    builder.addCase(uploadProfile.pending, (state) => {
      state.isProfileUpdatedLoading = true;
    });
    builder.addCase(uploadProfile.fulfilled, (state, action) => {
      state.isProfileUpdatedLoading = false;
      state.isProfileUpdated = true;
      state.profileMessage = action.payload;
    });
    builder.addCase(uploadProfile.rejected, (state, action) => {
      state.isProfileUpdatedLoading = false;
      state.isProfileUpdated = false;
      state.isProfileUpdatedFailed = true;
      state.profileError = action.error.message;
    });
    // this should be updating cover image
    builder.addCase(uploadCover.pending, (state) => {
      state.isCoverUpdatedLoading = true;
    });
    builder.addCase(uploadCover.fulfilled, (state, action) => {
      state.isCoverUpdatedLoading = false;
      state.isCoverUpdated = true;
      state.coverMessage = action.payload.message;
    });
    builder.addCase(uploadCover.rejected, (state, action) => {
      state.isCoverUpdatedLoading = false;
      state.isCoverUpdated = false;
      state.coverError = action.error.message;
      state.isCoverUpdatedFailed = true;
    });
    // this should be updating userinfo
    builder.addCase(updateInfo.pending, (state) => {
      state.isUpdateInfoLoading = true;
    });
    builder.addCase(updateInfo.fulfilled, (state, action) => {
      state.isUpdateInfoLoading = false;
      state.isUpdateInfo = true;
      state.infoUpdateSuccess = action.payload;
    });
    builder.addCase(updateInfo.rejected, (state, action) => {
      state.isUpdateInfoLoading = false;
      state.isUpdateInfo = false;
      state.infoUpdateError = action.error.message;
      state.isUpdateInfoFailed = true;
    });
    // User created and collected data
    builder.addCase(inWallet.pending, (state) => {
      state.isInWalletLoading = true;
    });
    builder.addCase(inWallet.fulfilled, (state, action) => {
      state.isInWalletLoading = false;
      state.isInWalletSuccess = true;
      state.inWallet = action.payload;
    });
    builder.addCase(inWallet.rejected, (state, action) => {
      state.isInWalletLoading = false;
      state.isInWalletSuccess = false;
    });
    // All Creatores
    builder.addCase(allCreators.pending, (state) => {
      state.isAllCreatorsLoading = true;
    });
    builder.addCase(allCreators.fulfilled, (state, action) => {
      state.isAllCreatorsLoading = false;
      state.isAllCreatorsSuccess = true;
      state.creators = action.payload;
    });
    builder.addCase(allCreators.rejected, (state, action) => {
      state.isAllCreatorsLoading = false;
      state.isAllCreatorsSuccess = false;
      state.isAllCreatorsFailed = true;
    });
    // Single Creator
    builder.addCase(singleCreator.pending, (state) => {
      state.isSingleCreatorLoading = true;
    });
    builder.addCase(singleCreator.fulfilled, (state, action) => {
      state.isSingleCreatorLoading = false;
      state.singleCreator = action.payload;
    });
    builder.addCase(singleCreator.rejected, (state, action) => {
      state.isSingleCreatorLoading = false;
      state.isSingleCreatorFailed = true;
    });
  },
});

export default profileSlicer.reducer;
export const {
  resetUpdateInfo,
  resetProfileUpdate,
  resetCoverUpdate,
  resetUsernameCheck,
} = profileSlicer.actions;
