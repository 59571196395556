import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home";
import Layout from "../components/layout";
import ArtDetails from "../pages/artDetails";
import Browse from "../pages/browse";
import EditProfile from "../pages/editProfile";
import UserProfile from "../pages/userProfile";
import Minting from "../components/Minting";
import AboutUs from "../pages/about";
import Wrapper from "../components/wrapper";
import RoadMap from "../pages/roadMap";
import LegalDesclaimer from "../pages/legalDesclaimer";
import Policies from "../pages/terms";
import Listing from "../pages/listing";
import Error404 from "../pages/404";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "/:tokenId/:amountId/:chainId", element: <ArtDetails /> },
      { path: "/listing/:tokenId/:amountId/:chainId", element: <Listing /> },
      { path: "/userprofile/:wallet", element: <UserProfile /> },
      { path: "/editprofile", element: <EditProfile /> },
      { path: "/create", element: <Minting /> },
      { path: "/browse", element: <Browse /> },
      { path: "/roadmap", element: <RoadMap /> },

      {
        path: "/legaldisclaimer",
        element: (
          <Wrapper>
            <LegalDesclaimer />
          </Wrapper>
        ),
      },
      {
        path: "/policies",
        element: (
          <Wrapper>
            {" "}
            <Policies />{" "}
          </Wrapper>
        ),
      },
      {
        path: "/about-us",
        element: (
          <Wrapper>
            {" "}
            <AboutUs />
          </Wrapper>
        ),
      },
    ],
  },
  //   {
  //     path: "/login",
  //     element: <AuthLayout />,
  //     children: [{ index: true, element: <Login /> }],
  //   },

  { path: "*", element: <Error404 /> },
]);

export default router;
