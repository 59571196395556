import { Box, Grid, Typography } from "@mui/material";
import CustomAccordian from "../accordion";
import Image from "../../assets/images/faqlmage.png";

const FAQ = () => {
  return (
    <Box py={{ lg: 6, md: 4, sm: 2, xs: 1 }}>
      <Box textAlign="center" pb={5}>
        <Typography
          variant="h2"
          color="#fff"
          fontSize="35.708px"
          fontWeight="700"
        >
          Frequently Asked Question
        </Typography>
      </Box>
      <Box padding="5px 0px">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box display="flex" justifyContent="center">
              <img
                src={Image}
                alt="Monkey"
                style={{
                  width: "27vw",
                  minWidth: "30vw",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6}>
            <Box>
              <CustomAccordian />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FAQ;
