import React from "react";
import { makeStyles } from "@mui/styles";
import SingleCard from "../card/singleCard";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  gallery: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
    gridGap: theme.spacing(1),
    padding: "15px 0",
    justifyItems: "center",
  },
}));

const NFTGallery = ({ title }) => {
  const classes = useStyles();
  const { AllArts } = useSelector((state) => state.arts);

  return (
    <Box>
      <Box textAlign={"center"}>
        <Typography
          sx={{
            color: "white",
            fontSize: { lg: "36px", md: "32px", sm: "27px" },
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
      </Box>
      <div className={classes.gallery}>
        {AllArts &&
          AllArts.map((items) => {
            return <SingleCard key={items.id} {...items} />;
          })}
      </div>
    </Box>
  );
};

export default NFTGallery;
