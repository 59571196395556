import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

export const CustomTabs = ({ label, index, value, ...props }) => {
  const classes = useStyles();
  return (
    <Box {...props} className={classes.tab}>
      <Typography
        variant="body1"
        fontWeight={600}
        className={clsx(classes.label, { [classes.selected]: index === value })}
      >
        {label}
      </Typography>
    </Box>
  );
};

export const Tabs = ({ value, children, onChange, className }) => {
  const classes = useStyles();
  const handleClick = (index) => {
    onChange(index);
  };
  const childrenWithProps = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      value: value,
      onClick: handleClick.bind(this, index),
      index: index,
    })
  );
  return (
    <Box className={clsx(className, classes.root)}>{childrenWithProps}</Box>
  );
};
export const EditTabs = ({ value, children, onChange, className }) => {
  const classes = useStyles();
  const handleClick = (index) => {
    onChange(index);
  };
  const childrenWithProps = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      value: value,
      onClick: handleClick.bind(this, index),
      index: index,
    })
  );
  return (
    <Box className={clsx(className, classes.editRoot)}>{childrenWithProps}</Box>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(8, 1fr)",
    background: "#2B213F",
    borderRadius: "10px !important",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1.5fr 1fr 1fr",
    },
  },
  tab: {
    width: "100%",
    padding: "5px 20px",

    borderBottom: "none",
    [theme.breakpoints.down("md")]: {
      padding: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 5px",
    },
  },
  selected: {
    position: "relative",
    borderRadius: "10px",
    "&::after": {
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: "100%",
      height: 5,
      content: "''",
      backgroundImage: "#fff",
      transition: ".25s",
    },
  },
  label: {
    fontFamily: "Montserrat,sans-serif",
    textDecoration: "none",
    color: "#ffffff",
    fontWeight: 700,
    padding: "10px 0px 10px 0px",
    position: "relative",
    cursor: "pointer",
    width: "fit-content",
    borderRadius: "10px !important",
    // width: "80px",
    "&::after": {
      position: "absolute",
      bottom: 5,
      left: "50%",
      transform: "translateX(-50%)",
      height: 2,
      content: "''",
      backgroundColor: "white",
      transition: ".25s",
      color: "black !important",
    },
  },
}));
