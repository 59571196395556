import { Avatar, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
// import verified from "../../../assets/icons/verified.svg";
import Button from "../../button";
import { useSelector } from "react-redux";
import { coverBaseURL, profileBaseURL } from "../../../http/config";
import noProfile from "../../../assets/images/noprofile.jpg";
import { useAddress } from "@thirdweb-dev/react";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { ShortString } from "../../../utils";
const UserDetails = () => {
  const wallet = useAddress();
  const classes = useStyles();
  const {
    singleCreator: {
      bio,
      img,
      cover,
      walletAddress,
      twitter,
      instagram,
      username,
    },
    isAuthenticationLoading,
  } = useSelector((state) => state.profile);

  return (
    <Box>
      <Box className={classes.imagesContainer}>
        <Box>
          <img src={coverBaseURL + cover} className={classes.image} alt="" />
        </Box>
        <Box className={classes.avatarContainer}>
          <Avatar
            alt="Profile"
            src={img === null ? noProfile : profileBaseURL + img}
            className={classes.avatar}
          />
        </Box>
      </Box>
      {/* User Informtaion */}
      <ProfileWrapper>
        <Box className={classes.detailContainer}>
          {isAuthenticationLoading ? (
            ""
          ) : (
            <Box className={classes.details}>
              <Typography className={classes.username}>
                <ShortString address={username} />
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                {instagram === null ? (
                  ""
                ) : (
                  <a
                    href={`https://www.instagram.com/${instagram}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <InstagramIcon className={classes.icon} />
                    {instagram}
                  </a>
                )}
                {twitter === null ? (
                  ""
                ) : (
                  <a
                    href={`https://twitter.com/${twitter}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <XIcon className={classes.icon} />
                    {twitter}
                  </a>
                )}
              </Box>
            </Box>
          )}

          <Box className={classes.buttonContainer}>
            <Typography
              sx={{ color: "rgba(255, 255, 255, 0.60)", fontSize: "19px" }}
            >
              {bio === null ? "Edit bio from edit profile" : bio}
            </Typography>
          </Box>
          {wallet === walletAddress && (
            <Box py={0.5}>
              <Button
                component={Link}
                to="/editprofile"
                variant="contained"
                sx={{ borderRadius: "0px !important" }}
              >
                {" "}
                Edit Profile
              </Button>
            </Box>
          )}
        </Box>
      </ProfileWrapper>
    </Box>
  );
};
export default UserDetails;
const ProfileWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        paddingLeft: { lg: 2.5, md: 1, sm: 1, xs: 1 },
        paddingRight: { lg: 2.5, md: 1, sm: 1, xs: 1 },
      }}
    >
      {children}
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  detailContainer: {
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  avatarContainer: {
    position: "relative",
    bottom: 40,
    left: "1.5%",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      left: "1%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "2%",
    },
  },
  details: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
    "& img": {
      width: "25px",
    },
  },
  username: {
    fontSize: "42px !important",
    fontWeight: 700 + "!important",
    textTransform: "capitalize",
  },
  image: {
    width: "100%",
    minHeight: "200px",
    maxHeight: "300px",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      minHeight: "200px",
      maxHeight: "200px",
    },
  },
  avatar: {
    width: "100px !important",
    height: "100px !important",
    border: "2px solid #E3FF00",
    [theme.breakpoints.down("md")]: {
      width: "80px !important",
      height: "80px !important",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: "40%",
    "& Typography": {
      color: "rgba(255, 255, 255, 0.60) !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  button: {
    width: "130px",
  },
  icon: {
    background: "#fff",
    color: "#000",
    borderRadius: "5px",
    fontSize: "32px",
    padding: 2,
  },
}));
