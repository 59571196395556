import { xNFTs } from "../../http/config";

export const checkEmailApi = async (payload) => {
  try {
    const res = await xNFTs.get(`checkEmail/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const checkUsernameApi = async (payload) => {
  try {
    const res = await xNFTs.get(`checkUsername/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const uploadProfileApi = async (payload) => {
  try {
    const res = await xNFTs.post("uploadProfilePicture", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
export const uploadCoverApi = async (payload) => {
  try {
    const res = await xNFTs.post("uploadCoverPicture", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateUserInfoApi = async (payload) => {
  try {
    const res = await xNFTs.post("updateInfo", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const inWalletApi = async (payload) => {
  try {
    const res = await xNFTs.get(`inWallet/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const allCreatorApi = async (payload) => {
  try {
    const res = await xNFTs.get("allCreators");
    return res;
  } catch (error) {
    throw error;
  }
};

export const getSingleCreatorApi = async (payload) => {
  try {
    const res = await xNFTs.get(`singleCreator/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};
