import { Box, CircularProgress, InputBase, Typography } from "@mui/material";
import Button from "../button";
import { Link } from "react-router-dom";
import {
  useContract,
  useAddress,
  useEnglishAuction,
  useChainId,
} from "@thirdweb-dev/react";

import { useDispatch, useSelector } from "react-redux";
import { directTransfer } from "../../redux/transfer/transfer.action";
import { useFormik } from "formik";
import { addBidding } from "../../redux/bid/bid.action";
import { profileBaseURL } from "../../http/config";
import image1 from "../../assets/images/noprofile.jpg";
import AuctionTimer from "./../timer";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useStyles } from "./style";

const CardDetail = ({
  title,
  description,
  reservePrice,
  highestBid,
  creatorUsername,
  ownerUsername,
  ownerWallet,
  amountId,
  tokenId,
  chainId,
  onAuction,
  fixedPrice,
  image,
  onSale,
  orderId,
  auctionId,
  creatorImg,
  ownerImg,
  setSubmitting,
  setFixedPrice,
  fixPriceSubmit,
  bidSubmit,
  properties,
}) => {
  const classes = useStyles();
  const { marketplaceContract, marketplaceType } = useSelector(
    (state) => state.mint
  );

  const [finalizeLoad, setFinalizeLoad] = useState(false);
  const [time, setTime] = useState(0);
  const dispatch = useDispatch();
  const currentChain = useChainId();
  const wallet = useAddress();
  const remainingTime = time - new Date().getTime();

  const { contract } = useContract(marketplaceContract, marketplaceType);
  const { data: englishAuction, isLoading } = useEnglishAuction(
    contract,
    auctionId
  );
  // const { mutateAsync: executeAuctionSale } = useExecuteAuctionSale(contract);
  useEffect(() => {
    var date = 0;
    if (onAuction && !isLoading) {
      date = new Date(
        englishAuction?.endTimeInSeconds !== 0 &&
          englishAuction?.endTimeInSeconds * 1000
      );

      setTime(date);
    }
  }, [onAuction, isLoading, englishAuction]);
  const handleFinalizeAuction = async () => {
    setFinalizeLoad(true);
    try {
      // const txt = await executeAuctionSale({
      //   listingId: auctionId,
      // });
      setFinalizeLoad(false);
    } catch (error) {
      setFinalizeLoad(false);
      console.log(error.message);
    }
  };
  const handleBuy = async () => {
    try {
      setFixedPrice(true);
      const txResult = await contract?.directListings.buyFromListing(
        orderId,
        1,
        ownerWallet
      );

      let payload = {
        tokenId: tokenId,
        orderId: orderId,
        transferHash: txResult.receipt.transactionHash,
        transferFrom: ownerWallet,
        transferTo: wallet,
        amount: fixedPrice, // Assuming fixedPrice is the transaction amount
        amountId: 1, // Assuming amountId represents the type or category of the amount
        chainId: chainId,
      };
      dispatch(directTransfer(payload));
    } catch (error) {
      console.error("Error:", error.message); // Log the error message
      // Handle the error or display a message to the user
    } finally {
      setFixedPrice(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bidAmount: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setSubmitting(true);

        const txResult = await contract?.englishAuctions.makeBid(
          auctionId,
          values.bidAmount
        );
        console.log(txResult, "trnsaction");
        let payload = {
          bidderId: wallet,
          tokenId: tokenId,
          auctionId: auctionId,
          amountId: 1,
          transferHash: txResult.receipt.transactionHash,
          amount: values.bidAmount,
          chainId: chainId,
        };
        dispatch(addBidding(payload));
      } catch (err) {
        console.log(err.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  let creator = creatorUsername;
  let creatorWallet =
    creator?.length > 12
      ? `${creator.substr(0, 4)}...${creator.substr(-4)}`
      : creator;
  ////////////////////////////////
  let owner = ownerUsername;
  let ownerName =
    owner?.length > 12 ? `${owner.substr(0, 4)}...${owner.substr(-4)}` : owner;
  const extension = image?.split(".").pop();
  return (
    <>
      <Box>
        {extension === "mp4" ||
        extension === "webm" ||
        extension === "mov" ||
        extension === "mpeg" ||
        extension === "quicktime" ||
        extension === "x-msvideo" ||
        extension === "x-ms-wmv" ? (
          <video
            src={image}
            className={classes.image_video}
            controls
            autoplay
            playsinline
          />
        ) : (
          <img src={image} className={classes.image_video} alt="" />
        )}
      </Box>
      <Box sx={{ padding: { lg: "0 40px", md: "0 10px" } }}>
        <Box>
          {" "}
          <Typography sx={{ fontSize: "48px", fontWeight: 700, color: "#fff" }}>
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "32px",
              color: "#ADB9C7",
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "32px",
              color: "#ADB9C7",
            }}
          >
            Property:
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "32px",
              color: "white",
            }}
          >
            {properties}
          </Typography>
        </Box>
        <Box py={3} sx={{ display: "flex", justifyContent: "space-between" }}>
          {onAuction === 0 ? (
            <Box>
              <Typography
                sx={{ fontSize: "24px", fontWeight: 700, color: "#fff" }}
              >
                Fix Price
              </Typography>
              <Typography
                sx={{ fontSize: "19px", fontWeight: 700, color: "#fff" }}
              >
                {fixedPrice}{" "}
                {chainId === 137
                  ? "MATIC"
                  : chainId === 1
                  ? "ETH"
                  : chainId === 8453
                  ? "BASE"
                  : "AVAL"}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                sx={{ fontSize: "24px", fontWeight: 700, color: "#fff" }}
              >
                Price Bid
              </Typography>
              <Typography
                sx={{ fontSize: "19px", fontWeight: 700, color: "#fff" }}
              >
                {reservePrice}{" "}
                {chainId === 137
                  ? "MATIC"
                  : chainId === 1
                  ? "ETH"
                  : chainId === 8453
                  ? "BASE"
                  : "AVAL"}
              </Typography>
            </Box>
          )}
          {onAuction === 1 && (
            <>
              <Box>
                {" "}
                <Typography
                  sx={{ fontSize: "24px", fontWeight: 700, color: "#fff" }}
                >
                  Last Bid
                </Typography>
                <Typography
                  sx={{ fontSize: "19px", fontWeight: 700, color: "#fff" }}
                >
                  {highestBid}{" "}
                  {chainId === 137
                    ? "MATIC"
                    : chainId === 1
                    ? "ETH"
                    : chainId === 8453
                    ? "BASE"
                    : "AVAL"}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            background: "#272D37",
            padding: "10px 20px",
            borderRadius: "10px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box>
              {" "}
              <img
                src={creatorImg === null ? image1 : profileBaseURL + creatorImg}
                alt=""
                style={{ width: "50px", height: "50px", borderRadius: "5px" }}
              />
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 400, color: "#8F9CA9" }}
              >
                Creator
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, color: "#fff" }}
              >
                {creatorWallet}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {" "}
            <Box>
              <img
                src={ownerImg === null ? image1 : profileBaseURL + ownerImg}
                alt=""
                style={{ width: "50px", height: "50px", borderRadius: "5px" }}
              />
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, color: "#8F9CA9" }}
              >
                Owner
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, color: "#fff" }}
              >
                {ownerName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box> {time !== 0 && <AuctionTimer expiryTimestamp={time} />}</Box>
        <form onSubmit={formik.handleSubmit}>
          {onAuction === 1 && remainingTime > 0 && (
            <>
              <Box py={1}>
                <Typography
                  py={1}
                  sx={{ fontSize: "20px", fontWeight: 700, color: "#fff" }}
                >
                  Place your Bid
                </Typography>
                <InputBase
                  sx={{
                    backgroundColor: "#2A203E !important",
                    color: "#7C7C7C !important",
                    fontSize: "18px !important",
                    fontWeight: 400 + "!important",
                    padding: 1,
                    borderRadius: "10px !important",
                    width: "100% !important",
                  }}
                  placeholder="eg. Place your Bid"
                  // className={classes.nameInput}
                  name="bidAmount"
                  type="float"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.bidAmount && formik.errors.bidAmount ? (
                  <Typography style={{ color: "red", fontSize: "14px", pl: 2 }}>
                    {formik.errors.bidAmount}
                  </Typography>
                ) : null}
                {formik.values.bidAmount ? (
                  <>
                    {reservePrice ||
                      (highestBid >= formik.values.bidAmount && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          Higher your bid then current Bid
                        </span>
                      ))}
                  </>
                ) : (
                  ""
                )}
              </Box>
            </>
          )}
          {onSale === 1 && (
            <Box>
              {onAuction === 0 ? (
                <Box py={3}>
                  {wallet !== ownerWallet && (
                    <>
                      {chainId === currentChain ? (
                        <Button
                          onClick={handleBuy}
                          variant="contained"
                          sx={{
                            borderRadius: "2px !important",
                            background: "rgba(120, 121, 241, 0.33)",
                            border: "1.5px solid #7879F1",
                            padding: "10px 40px !important",
                          }}
                        >
                          {fixPriceSubmit ? (
                            <CircularProgress
                              size={"18px"}
                              sx={{ color: "#fff" }}
                            />
                          ) : (
                            "Buy Now"
                          )}
                        </Button>
                      ) : (
                        <Typography sx={{ color: "white", fontSize: "18px" }}>
                          To buy this NFT, Change your chain to{" "}
                          <span style={{ color: "red" }}>
                            {chainId === 137 ? "MATIC" : "ETH"}
                          </span>
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              ) : remainingTime > 0 ? (
                <Box py={1}>
                  <>
                    {chainId === currentChain ? (
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          borderRadius: "2px !important",
                          background: "rgba(120, 121, 241, 0.33)",
                          border: "1.5px solid #7879F1",
                          padding: "10px 40px !important",
                        }}
                      >
                        {bidSubmit ? (
                          <CircularProgress
                            size={"18px"}
                            sx={{ color: "#fff" }}
                          />
                        ) : (
                          "Bid Now"
                        )}
                      </Button>
                    ) : (
                      <Typography sx={{ color: "white", fontSize: "18px" }}>
                        To Bid this NFT, Change your chain to{" "}
                        <span style={{ color: "red" }}>
                          {chainId === 137
                            ? "MATIC"
                            : chainId === 1
                            ? "ETH"
                            : chainId === 8453
                            ? "BASE"
                            : "AVAL"}
                        </span>
                      </Typography>
                    )}
                  </>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleFinalizeAuction}
                  sx={{
                    borderRadius: "2px !important",
                    background: "rgba(120, 121, 241, 0.33)",
                    border: "1.5px solid #7879F1",
                    padding: "10px 40px !important",
                    marginY: "10px !important",
                  }}
                >
                  {finalizeLoad ? (
                    <CircularProgress size={"18px"} sx={{ color: "#fff" }} />
                  ) : (
                    "Finalize Auction"
                  )}
                </Button>
              )}
            </Box>
          )}

          {wallet === ownerWallet && onSale !== 1 && (
            <>
              <Box sx={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  component={Link}
                  to={`/listing/${tokenId}/${amountId}/${chainId}`}
                  sx={{
                    borderRadius: "2px !important",
                    background: "rgba(120, 121, 241, 0.33)",
                    border: "1.5px solid #7879F1",
                    padding: "10px 40px !important",
                  }}
                >
                  List Now
                </Button>
              </Box>
            </>
          )}
        </form>
      </Box>
    </>
  );
};

export default CardDetail;

const validationSchema = Yup.object().shape({
  bidAmount: Yup.string().required("Enter amount for bidding"),
});
