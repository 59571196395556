import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useScrollToTop from "../../components/scrollTop";

const Policies = () => {
  const classes = useStyles();
  useScrollToTop();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <Box textAlign="center" pb={4}>
          {" "}
          <Typography
            variant="h2"
            sx={{ fontSize: "30px", fontWeight: 700, color: "white" }}
          >
            Terms and Services
          </Typography>
        </Box>
        <Box className={classes.header}>
          <Typography variant="h2" className={classes.title}>
            Artwork Submission Guidelines
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography variant="body1" className={classes.description}>
            <strong>Eligibility:</strong> Open exclusively to AI artists
            worldwide. Each submission must be original, AI-generated, and
            comply with our content standards.
          </Typography>
          <Typography variant="body1" className={classes.description}>
            <strong>Minting Process:</strong> Artworks are minted as NFTs on the
            Polygon blockchain, ensuring efficiency, low transaction costs, and
            environmental sustainability.
          </Typography>
          <Typography variant="body1" className={classes.description}>
            <strong>Intellectual Property:</strong> Artists retain copyright of
            their work. By submitting your artwork, you grant xNFTs the right to
            display and promote your art on our platform and associated
            channels.
          </Typography>
        </Box>

        <Box className={classes.header}>
          <Typography variant="h2" className={classes.title}>
            Community Conduct
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography variant="body1" className={classes.description}>
            We value respect, integrity, and creativity. Harassment, copyright
            infringement, and any form of unethical behavior are strictly
            prohibited.
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Feedback and constructive criticism are encouraged, fostering a
            supportive environment for all members.
          </Typography>
        </Box>

        <Box className={classes.header}>
          <Typography variant="h2" className={classes.title}>
            Privacy & Security
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography variant="body1" className={classes.description}>
            Protecting your data is a top priority. Personal information is
            securely stored and never shared without consent.
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Transactions are conducted on the blockchain, providing a secure,
            transparent record of ownership and sales.
          </Typography>
        </Box>

        <Box className={classes.header}>
          <Typography variant="h2" className={classes.title}>
            Joining xNFTs
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography variant="body1" className={classes.description}>
            Interested artists are invited to apply through our website. Please
            include a brief bio, examples of your work, and your expectations
            from the platform.
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Our team will review submissions, focusing on creativity,
            originality, and alignment with our mission.
          </Typography>
        </Box>

        <Typography variant="body1" className={classes.endingDescription}>
          We at xNFTs are committed to revolutionizing the world of digital art,
          making it more accessible, secure, and rewarding for artists and
          collectors alike. Together, let's shape the future of art.
        </Typography>
      </Box>
    </Box>
  );
};

export default Policies;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "50px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 0px 30px 0px",
    },
  },
  container: {
    backgroundColor: "#2b2140",
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: 10,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: "2rem !important",
    color: "white",
    fontWeight: 700,
  },
  content: {
    color: "white",
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "0px 0px",
  },
  description: {
    fontSize: "1rem",
    marginBottom: theme.spacing(2),
    display: "block",
  },
  endingDescription: {
    fontSize: "1rem !important",
    fontWeight: 600 + "!important",
    textAlign: "center",
    color: "#fff",
  },
}));
