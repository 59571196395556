import { Box } from "@mui/material";
import SortDropdown from "../filters/browseSort";
import SearchFilter from "../filters/searchFilter";

const BrowseFilters = ({ data, setSortedData }) => {
  const priceSortingOptions = [
    {
      value: "price",
      defaultLabel: "Price",
      disabled: true,
    },
    {
      value: "priceLowest",
      label: "Price: Lowest",
      sortFunction: (a, b) => a.fixedPrice - b.fixedPrice,
    },
    {
      value: "priceHighest",
      label: "Price: Highest",
      sortFunction: (a, b) => a.fixedPrice - b.fixedPrice,
    },
    {
      value: "dateOldest",
      label: "Date: Oldest",
      sortFunction: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      value: "dateNewest",
      label: "Date: Newest",
      sortFunction: (a, b) => new Date(b.created_at) - new Date(a.created_at),
    },
  ];
  const categorySortingOptions = [
    {
      value: "price",
      defaultLabel: "Category",
      disabled: true,
    },
    {
      value: "arts",
      label: "Arts",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
    {
      value: "gaming",
      label: "Gaming",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
    {
      value: "photography",
      label: "Photography",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
    {
      value: "space",
      label: "Space",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
  ];
  const categoryTypeOptions = [
    {
      value: "price",
      defaultLabel: "Type",
      disabled: true,
    },
    {
      value: "arts",
      label: "Arts",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
    {
      value: "gaming",
      label: "Gaming",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
    {
      value: "photography",
      label: "Photography",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
    {
      value: "space",
      label: "Space",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
  ];
  const categoryStatusOptions = [
    {
      value: "price",
      defaultLabel: "Status",
      disabled: true,
    },
    {
      value: "arts",
      label: "Arts",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.category),
    },
    {
      value: "gaming",
      label: "Gaming",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
    {
      value: "photography",
      label: "Photography",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
    {
      value: "space",
      label: "Space",
      sortFunction: (a, b) => a.categoryTitle.localeCompare(b.categoryTitle),
    },
  ];
  const recentlySortingOptions = [
    {
      value: "price",
      defaultLabel: "Recently Added",
      disabled: true,
    },
    {
      value: "priceLowest",
      label: "Price: Lowest",
      sortFunction: (a, b) => a.fixedPrice - b.fixedPrice,
    },
    {
      value: "priceHighest",
      label: "Price: Highest",
      sortFunction: (a, b) => b.fixedPrice - a.fixedPrice,
    },
    {
      value: "dateOldest",
      label: "Date: Oldest",
      sortFunction: (a, b) => new Date(a.danftNamete) - new Date(b.date),
    },
    {
      value: "dateNewest",
      label: "Date: Newest",
      sortFunction: (a, b) => new Date(b.date) - new Date(a.date),
    },
  ];
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();

    const filteredResults = data.filter(
      (item) => item.title && item.title.toLowerCase().includes(query)
    );

    setSortedData(filteredResults);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: "#2D2342",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
        <SortDropdown
          data={data}
          setSortedData={setSortedData}
          sortingOptions={priceSortingOptions}
        />
        <SortDropdown
          data={data}
          setSortedData={setSortedData}
          sortingOptions={categorySortingOptions}
        />
        <SortDropdown
          data={data}
          setSortedData={setSortedData}
          sortingOptions={categoryTypeOptions}
        />
        <SortDropdown
          data={data}
          setSortedData={setSortedData}
          sortingOptions={categoryStatusOptions}
        />
      </Box>

      <Box display="flex" gap="20px" width="100%" pl={5}>
        <SearchFilter
          handleChange={handleSearchChange}
          placeholder="Search by NFT name"
        />
        <SortDropdown
          data={data}
          setSortedData={setSortedData}
          sortingOptions={recentlySortingOptions}
        />
      </Box>
    </Box>
  );
};
export default BrowseFilters;
