import { Box } from "@mui/material";
import Banner from "../../components/banner";
import Wrapper from "../../components/wrapper";
import { TableContainer } from "../../components/table/TableContainer";
import FAQ from "../../components/faq";
import CreateAiNftFaq from "../../components/createAiNftfaq";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllArts, getBitCoinData } from "../../redux/arts/arts.action";
import Notifications from "../../components/notification";
import NFTGallery from "../../components/nftGallery";
import useScrollToTop from "../../components/scrollTop";

const Home = () => {
  const dispatch = useDispatch();
  useScrollToTop();
  useEffect(() => {
    dispatch(getAllArts());
    dispatch(getBitCoinData());
  }, [dispatch]);
  return (
    <Box sx={{ backgroundColor: "#13082A" }}>
      <Notifications />
      <Box pb={4}>
        <Wrapper>
          <Box>
            <Banner />
          </Box>
          <Box pt={4}>
            <TableContainer />
          </Box>
        </Wrapper>
      </Box>
      {/* Slider Cards */}
      {/* <Box>
        <Slider title="Featured Collections" btnLabel="View More" />
      </Box>
      <Box>
        <Slider title="Auction Artworks" btnLabel="View More" />
      </Box>{" "}
      <Box>
        <Slider title="Fixed price artworks" btnLabel="View More" />
      </Box>{" "} */}
      <NFTGallery title="NFT Collections" />
      <Box>
        <CreateAiNftFaq />
      </Box>
      <Wrapper>
        <FAQ />
      </Wrapper>
    </Box>
  );
};
export default Home;
