import { xNFTs } from "../../http/config";

export const directTransferApi = async (payload) => {
  try {
    const res = await xNFTs.post("directTransfer", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
//////////////////////////////////////////////////////////
export const auctionTransferApi = async (payload) => {
  try {
    const res = await xNFTs.get("auctionTransfer", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
