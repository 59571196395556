import userProfile from "../../assets/images/noprofile.jpg";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { profileBaseURL } from "../../http/config";
import { Box, Typography } from "@mui/material";
import React from "react";

const PreviewImage = React.memo(({ value, name, description }) => {
  const {
    user: { username, img },
  } = useSelector((state) => state.auth);
  const classes = useStyles();
  let owner = username;
  let creator =
    owner?.length > 12 ? `${owner.substr(0, 4)}...${owner.substr(-4)}` : owner;
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.userDetails}>
        <img
          className={classes.userImage}
          src={img ? profileBaseURL + img : userProfile}
          alt=""
        />
        <Typography className={classes.userName}>{creator}</Typography>
      </Box>
      <Box className={classes.previewContainer}>
        {!value ? (
          <>
            <Typography className={classes.innerText}>Preview Image</Typography>
            <span className={classes.span}>
              JPG,JPEG,PNG,GIF,SVG. Max size 100mb
            </span>
          </>
        ) : (
          <>
            {value.type === "video/mp4" ||
            value.type === "video/webm" ||
            value.type === "video/mov" ||
            value.type === "video/mpeg" ||
            value.type === "video/quicktime" ||
            value.type === "video/x-msvideo" ||
            value.type === "video/x-ms-wmv" ? (
              <video
                src={value && URL.createObjectURL(value)}
                width="100%"
                height="360"
                controls
                autoplay
                playsinline
              />
            ) : (
              <img
                src={value && URL.createObjectURL(value)}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  height: "350px",
                  objectFit: "cover",
                }}
              />
            )}
          </>
        )}
      </Box>
      <Box pb={1}>
        <Typography className={classes.userName}>
          {name === "" ? "Your NFT Name" : name}
        </Typography>
        <Typography className={classes.descriptionTitle}>
          {description === "" ? "NFT Description" : description}
        </Typography>
      </Box>
    </Box>
  );
});
export default PreviewImage;
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#261C3B",
    borderRadius: "10px",
    border: "none",
    maxWidth: "450px !important ",
    padding: "20px 20px",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: "400px",
    minHeight: "500px !important",
  },
  userDetails: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  previewContainer: {
    background: "linear-gradient(to right, #1E0C43, #140830)",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "350px !important",
    maxHeight: "350px !important",
    flexDirection: "column",
    gap: "10px",
    textAlign: "center",
    minWidth: "300px",
  },
  userName: {
    color: "#FFFFFF",
    fontWeight: 700 + "!important",
    textTransform: "capitalize !important",
  },
  userImage: {
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    objectFit: "cover",
  },
  innerText: {
    color: "#7C7C7C",
  },
  span: {
    color: "#7C7C7C",
    fontSize: "10px",
  },
  descriptionTitle: {
    color: "rgba(255, 255, 255, 0.60)",
    fontSize: "14px !important",
    fontWeight: 400 + "!important",
  },
}));
