import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/nftLogo.png";

import {
  ConnectWallet,
  useAddress,
  useContract,
  useRoleMembers,
} from "@thirdweb-dev/react";
import HamBurger from "../hamBurger";
import { useDispatch, useSelector } from "react-redux";
import { resetStateAuth } from "../../redux/auth/auth.slicer";
import MouseOverPopover from "../popper";
import SearchFilter from "../filters/searchFilter";
import { searchUser } from "../../redux/auth/auth.action";
import SearchPlace from "../searchPlace";
import Chains from "../chains";

const Header = () => {
  const { collectionContract } = useSelector((state) => state.mint);
  const dispatch = useDispatch();
  const [cartOpen, setCartOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [, setOpen] = React.useState(false);
  const [close, setClose] = useState(false);

  const [minter, setMinter] = useState(false);
  const { contract } = useContract(collectionContract);
  const { isSearchParamsSuccess, isSearchParamsLoading } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (isSearchParamsSuccess || isSearchParamsLoading) {
      setOpen(true);
    }
  }, [isSearchParamsSuccess, isSearchParamsLoading]);
  const wallet = useAddress();
  const { data: members, isLoading } = useRoleMembers(contract, "minter");
  useEffect(() => {
    if (isLoading) {
      setMinter(false);
    } else {
      if (members.includes(wallet)) {
        setMinter(true);
      } else {
        setMinter(false);
      }
    }
  }, [isLoading, members, wallet]);
  const {
    auth: { isAuthenticated },
  } = useSelector((state) => state);
  const toggleDrawer = (newOpen) => () => {
    setCartOpen(newOpen);
  };

  const handleLogout = () => {
    dispatch(resetStateAuth());
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleSearchParams = (e) => {
    const query = e.target.value.toLowerCase();

    dispatch(searchUser(query));
    setClose(true);
  };

  return (
    <Box
      sx={{
        backdropFilter: "blur(25px)",
        background:
          "linear-gradient(106.6deg, rgba(19, 8, 42, 0.32) 1.44%, rgba(126, 126, 126, 0.05) 108.41%)",
        padding: "20px 0",
        boxShadow: "",
      }}
    >
      <Box
        sx={{
          display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
          alignItems: "center",
          justifyContent: "space-between",
          color: "#000 !important",
        }}
        px={2.5}
      >
        <Box
          display="flex"
          alignItems="center"
          component={Link}
          to="/"
          sx={{ textDecoration: "none" }}
        >
          <img src={logo} width="70px" alt="" />
        </Box>
        <Box display="flex" alignItems="center" gap="40px">
          {isAuthenticated ? (
            minter ? (
              <Link to="/create" style={{ textDecoration: "none" }}>
                <Typography variant="body1" color="#FFF">
                  Create
                </Typography>
              </Link>
            ) : (
              <MouseOverPopover
                handlePopoverOpen={handlePopoverOpen}
                handlePopoverClose={handlePopoverClose}
                anchorEl={anchorEl}
                label="Create"
                message={true}
              />
            )
          ) : (
            <MouseOverPopover
              handlePopoverOpen={handlePopoverOpen}
              handlePopoverClose={handlePopoverClose}
              anchorEl={anchorEl}
              label="Create"
              message={false}
            />
          )}

          <Typography
            component={Link}
            to="/browse"
            variant="body1"
            color="white"
            sx={{ textDecoration: "none" }}
          >
            Browse
          </Typography>

          <Typography
            component={Link}
            to="/roadmap"
            variant="body1"
            color="white"
            sx={{ textDecoration: "none" }}
          >
            Road Map
          </Typography>
        </Box>
        <Box sx={{ width: "450px", position: "relative" }}>
          <Box sx={{ width: "100%" }}>
            <SearchFilter
              placeholder="Search...."
              handleChange={handleSearchParams}
            />
          </Box>
          {isSearchParamsSuccess && close ? (
            <SearchPlace setClose={setClose} close={close} />
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
          }}
        >
          {isAuthenticated ? (
            <Typography
              component={Link}
              to={`userprofile/${wallet}`}
              variant="body1"
              color="white"
              sx={{ textDecoration: "none" }}
            >
              My Wallet
            </Typography>
          ) : (
            <MouseOverPopover
              handlePopoverOpen={handlePopoverOpen}
              handlePopoverClose={handlePopoverClose}
              anchorEl={anchorEl}
              label="My Wallet"
            />
          )}
          <Chains />
          <ConnectWallet />
        </Box>
      </Box>
      <Box>
        <HamBurger
          toggleDrawer={toggleDrawer}
          cartOpen={cartOpen}
          handleLogout={handleLogout}
          isAuthenticated={isAuthenticated}
        />
      </Box>
    </Box>
  );
};

export default Header;
