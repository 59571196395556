import * as React from "react";
import { CustomTabs, Tabs } from "../tabs/customTab";
import { Box } from "@mui/material";
import TabPanel from "../tabs/tabPanel";
import ProfileFilters from "../profileFilters";
import SingleCard from "../card/singleCard";
import MintingRole from "../minterRole";
import { useContract, useRoleMembers, useAddress } from "@thirdweb-dev/react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const ProfileWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        paddingLeft: { lg: 2.5, md: 1, sm: 1, xs: 1 },
        paddingRight: { lg: 2.5, md: 1, sm: 1, xs: 1 },
      }}
    >
      {children}
    </Box>
  );
};

const ProfileTabs = ({ createdArts, collectedArts }) => {
  const { collectionContract } = useSelector((state) => state.mint);
  const [admin, setAdmin] = useState(false);
  const { contract } = useContract(collectionContract);
  const wallet = useAddress();

  const { data: members, isLoading } = useRoleMembers(contract, "admin");
  const { data: minters } = useRoleMembers(contract, "minter");
  useEffect(() => {
    if (isLoading) {
      setAdmin(false);
    } else {
      if (members?.includes(wallet)) {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
    }
  }, [isLoading, members, wallet]);

  const [value, setValue] = React.useState(0);
  const [filter, setFilter] = React.useState(createdArts);
  const [collectionFilter, setCollectionFilter] = React.useState(collectedArts);
  const tabs = ["Created", "Collected"];
  if (admin) {
    tabs.push("Approvals");
    tabs.push("Admin");
  }
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();

    const filteredResults = createdArts.filter(
      (item) => item?.title && item?.title.toLowerCase().includes(query)
    );
    const collected = createdArts.filter(
      (item) => item?.title && item?.title.toLowerCase().includes(query)
    );
    setCollectionFilter(collected);
    setFilter(filteredResults);
  };

  return (
    <Box>
      <ProfileWrapper>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map((items) => (
            <CustomTabs label={items} />
          ))}
        </Tabs>
        <Box>
          <ProfileFilters
            handleChange={handleSearchChange}
            data={createdArts || collectedArts}
            setSortedData={setFilter}
            setFilter={setFilter}
          />
        </Box>
        <Box>
          <TabPanel value={value} index={0}>
            {filter &&
              filter.map((items) => {
                return <SingleCard {...items} />;
              })}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {collectionFilter &&
              collectionFilter.map((items) => {
                return <SingleCard {...items} />;
              })}
          </TabPanel>

          <TabPanel value={value} index={2}>
            <MintingRole type="approvals" data={minters} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <MintingRole type="admin" data={minters} />
          </TabPanel>
        </Box>
      </ProfileWrapper>
    </Box>
  );
};
export default ProfileTabs;
