export const data = [
  {
    id: 1,
    phase: "Phase 1",
    title: "Initial Development and Multichain Implementation",
    primary: {
      heading: "Multichain Implementation",
      description:
        "Adapt the platform to support multiple blockchains, including Ethereum (ETH), Base, Polygon, and Avalanche (AVAL), to ensure versatility and accessibility for users.",
      headingTwo: "Minting Feature Development:",
      descriptionTwo:
        "Develop advanced features to enable the minting of both AI-generated NFTs and individual RWAs.",
    },
  },
  {
    id: 2,
    phase: "Phase 2",
    title: "Official Launch and Artist Onboarding",
    primary: {
      heading: "Official Platform Launch with Multichain Support",
      description:
        "Announce the launch featuring multichain support that allows users to choose their preferred blockchain for minting and trading NFTs.",
      headingTwo: "Initial Onboarding of Artists and Collectors",
      descriptionTwo:
        "Attract artists and collectors by offering an easy-to-use platform with technical support for minting AI NFTs and RWAs.",
    },
  },
  {
    id: 3,
    phase: "Phase 3",
    title: "Service Expansion and Community Engagement",
    primary: {
      heading: "Introduction of RWA Collections",
      description:
        "Develop and implement the functionality that will allow users to create and manage RWA collections.",
      headingTwo: "Engagement Programs",
      descriptionTwo:
        "Launch incentive programs for artists and collectors, such as rewards for those who are most active or promote the platform through word-of-mouth.",
    },
  },
  {
    id: 4,
    phase: "Phase 4",
    title: "Strategic Collaborations and Partnerships",
    primary: {
      heading: "Partnerships with Other Sectors:",
      description:
        "Establish collaborations with entities and companies in the arts, technology, and luxury goods sectors to expand the RWA offerings.",
      headingTwo: "Events and Workshops",
      descriptionTwo:
        "Organize events to educate users about new features and to strengthen the community.",
    },
  },
  {
    id: 5,
    phase: "Phase 5",
    title: "Continuous Innovation and Updates",
    primary: {
      heading: "Implementation of New Technologies",
      description:
        "Adopt new blockchain and AI technologies to enhance security, efficiency, and user experience on the platform.",
      headingTwo: "Feedback and Continuous Improvements",
      descriptionTwo:
        "Actively listen to the community for updates and continuous improvements based on user needs.",
    },
  },
  {
    id: 6,
    phase: "Phase 6",
    title: "Sustainability and Social Responsibility",
    primary: {
      heading: "Sustainability Initiatives",
      description:
        "Promote minting and NFT management practices that are environmentally friendly, favoring the use of eco-sustainable blockchains.",
      headingTwo: "Support for Social Causes",
      descriptionTwo:
        " A portion of the proceeds from selected collections could be donated to support social or cultural causes, strengthening the connection between xNFTs and corporate social responsibility.",
    },
  },
];
