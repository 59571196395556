import React from "react";
import { Box, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import Carousel from "react-elastic-carousel";
import AifaqImage from "../../assets/images/AifaqImage.png";

const CreateAiNftFaq = () => {
  const isMobileView = useMediaQuery("(max-width:800px)");
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 700, itemsToShow: 2, itemsToScroll: 2, itemPadding: [0, 10] },
    { width: 1150, itemsToShow: 3, itemsToScroll: 3, itemPadding: [0, 20] },
    { width: 1200, itemsToShow: 3, itemPadding: [0, 20] },
  ];
  return (
    <Box py={{ lg: 6, md: 4, sm: 2, xs: 1 }} px={2}>
      <Stack
        direction={isMobileView ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: "2rem 2rem" }}
        gap={4}
        flexWrap="wrap"
      >
        <Stack gap={{ md: 10, sm: 2, xs: 2 }}>
          <Typography
            sx={{
              color: "white",
              fontSize: { lg: "36px", md: "32px", sm: "27px" },
              fontWeight: 700,
              width: "fit-content",
            }}
          >
            Create AI-Generated NFTs
          </Typography>
          <Typography
            variant="body1"
            color="#FFFFFF"
            sx={{
              width: { lg: "650px", md: "100%" },
            }}
          >
            Unlockthe future of digital creativity with xNFTs—your premier
            platformfor crafting and minting AI-generated NFTs. Embrace the
            power ofartificial intelligence to transform your artistic vision
            intounique, collectible pieces of digital art. Whether you're
            anexperienced AI artist or just beginning to explore the
            possibilitiesof generative art, xNFTs provides the tools, support,
            and communityyou need to bring your creations to life.
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <img src={AifaqImage} alt="AifaqImage" width="80%" />
        </Stack>
      </Stack>
      <Carousel
        autoPlaySpeed={5000}
        enableAutoPlay={true}
        breakPoints={breakPoints}
        disableArrowsOnEnd={true}
        infinite={true}
        pagination={isMobileView ? true : false}
        showArrows={false}
      >
        {features.map((feature) => (
          <Paper
            key={feature.title}
            variant="outline"
            elevation={2}
            sx={{
              textAlign: "center",
              backgroundColor: "#FFFFFF1A",
              padding: isMobileView ? "1rem" : "2rem",
              maxWidth: { lg: "30vw", md: "100%" },
              maxHeight: "200px",
              minHeight: "200px",
              borderBottom: "2px solid grey",
              borderLeft: "1px solid rgba(128, 128, 128, 0.3)",
              borderRight: "1px solid rgba(128, 128, 128, 0.3)",
            }}
          >
            <Typography variant="subtitle1" color="#FFFFFF">
              {feature.title}
            </Typography>
            <Typography variant="caption" color="#FFFFFF">
              {feature.description}
            </Typography>
          </Paper>
        ))}
      </Carousel>
    </Box>
  );
};

export default CreateAiNftFaq;

const features = [
  {
    title: "Mint with Ease",
    description:
      "Our intuitive platform simplifies the process of minting your	AI-generated creations into NFTs, making it accessible to artists at	any skill level. Securely register your artwork on the blockchain,	ensuring authenticity and ownership.",
  },
  {
    title: "Reach a Global Audience",
    description:
      "Connect with collectors, enthusiasts, and fellow artists in a	vibrant marketplace dedicated to AI-generated art. Showcase your	work to a global audience eager to explore and invest in the future	of digital creativity.",
  },
  {
    title: "Collaborate and Innovate",
    description:
      "Join a community of forward-thinking artists in pushing the envelope	of what's possible with AI and digital art. Collaborate, share	insights, and find inspiration in a supportive environment that	celebrates innovation.",
  },
];
