import { useFormik } from "formik";
import { useStyles } from "./style";
import ImageUpload from "../imageUpload";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputBase,
  Typography,
} from "@mui/material";
import NFTDetails from "../nftDetails";
import PreviewImage from "../imagePreview";
import Button from "../button";
import * as Yup from "yup";
import { useContract, useAddress, useChainId } from "@thirdweb-dev/react";
import { useDispatch, useSelector } from "react-redux";
import { mint } from "../../redux/mint/mint.action";
import Notifications from "../notification";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Minting = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const { isMintSuccess, collectionContract, collectionType } = useSelector(
    (state) => state.mint
  );
  const { contract, isLoading } = useContract(
    collectionContract,
    collectionType
  );
  const wallet = useAddress();
  const chainId = useChainId();

  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: "",
      title: "",
      description: "",
      properties: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setSubmitting(true);

        const data = {
          name: values.title,
          description: values.description,
          image: values.image,
          overrides: {
            overrides: { gasLimit: 2000000000000000000000 },
          },
        };
        const tx = await contract.erc721.mintTo(wallet, data);

        const transactionHash = tx.receipt.transactionHash; // the transaction receipt with hash
        const token = tx.id; // the id of the NFT minted
        const nft = await tx.data();

        const metadata = nft.metadata;

        let tokenId = parseInt(token._hex, 16);

        const apiPayload = {
          title: values.title,
          description: values.description,
          creatorWallet: wallet,
          tokenId: tokenId,
          properties: values.properties,
          amount: 1,
          metadata: metadata.uri,
          transactionHash: transactionHash,
          image: metadata.image,
          categoryId: 1,
          chainId: chainId,
        };

        dispatch(mint(apiPayload));
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
  });
  console.log(formik.values);
  const handleCheckboxChange = (option) => {
    formik.setFieldValue(
      "properties",
      formik.values.properties === option ? "" : option
    );
  };
  const classes = useStyles();
  useEffect(() => {
    if (isMintSuccess) {
      setTimeout(() => {
        navigate(`/userprofile/${wallet}`);
      }, 3400);
    }
  }, [isMintSuccess, navigate, wallet]);

  return (
    <Box
      sx={{ backgroundColor: "#13082A !important" }}
      className={classes.mainContainer}
    >
      <Notifications submitting={submitting} />
      <Box className={classes.leftContainer}>
        {/* <Box>
          <UploadIcon formik={formik} value={formik.values.image} />
        </Box> */}
        <Box
          sx={{
            padding: {
              lg: "0px 0px",
              md: "0px 10px",
              xs: "0px 15px",
              sm: "0px 15px",
            },
          }}
        >
          <NFTDetails />
        </Box>
        <Box className={classes.container}>
          <Box>
            <Typography className={classes.title}>
              Add NFT image <span>*</span>
            </Typography>
            <ImageUpload
              formik={formik}
              value={formik.values.image}
              helperText={
                (formik.touched.image && formik.errors.image) || `&nbsp;`
              }
              error={formik.touched.image && Boolean(formik.errors.image)}
            />
            {formik.touched.image && formik.errors.image ? (
              <Typography sx={{ color: "red", fontSize: "14px", pl: 1 }}>
                {formik.errors.image}
              </Typography>
            ) : null}
          </Box>
          <Box>
            <Typography className={classes.title}>
              Name <span>*</span>
            </Typography>
            <InputBase
              sx={{ backgroundColor: "#2A203E !important" }}
              placeholder="eg. Name your NFT“"
              className={classes.nameInput}
              name="title"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {formik.touched.title && formik.errors.title ? (
              <Typography sx={{ color: "red", fontSize: "14px", pl: 1 }}>
                {formik.errors.title}
              </Typography>
            ) : null}
          </Box>
          <Box>
            <Typography className={classes.title}>
              Description<span>*</span>
            </Typography>
            <Typography className={classes.description}>
              The description will be included on the item’s detail page
              underneath it’s image.
            </Typography>
            <textarea
              placeholder="eg. “Description your NFT”"
              className={classes.textArea}
              name="description"
              type="text"
              rows={7}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.description && formik.errors.description ? (
              <Typography sx={{ color: "red", fontSize: "14px", pl: 1 }}>
                {formik.errors.description}
              </Typography>
            ) : null}
          </Box>

          {/* <Box>
            <Properties formik={formik} />
          </Box> */}
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                background: "#2A203E",
                borderRadius: "5px",
              }}
            >
              <FormControlLabel
                sx={{
                  padding: "10px",
                  "&.Mui-checked": {
                    backgroundColor: "white",
                  },
                }}
                control={
                  <Checkbox
                    checked={formik.values.properties === "AI nft"}
                    onChange={() => handleCheckboxChange("AI nft")}
                    sx={{}}
                  />
                }
                label="AI nft"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.properties === "RWA"}
                    onChange={() => handleCheckboxChange("RWA")}
                  />
                }
                label="RWA"
              />
            </Box>
            {formik.touched.properties && formik.errors.properties ? (
              <Typography
                sx={{ color: "red", fontSize: "14px", pl: 1, pt: 0.5 }}
              >
                {formik.errors.properties}
              </Typography>
            ) : null}
          </Box>
          <Box>
            <Button
              disabled={isLoading}
              variant="contained"
              onClick={formik.handleSubmit}
              className={classes.button}
            >
              {submitting ? (
                <CircularProgress sx={{ color: "#fff" }} size={"18px"} />
              ) : (
                "Mint NFT"
              )}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={classes.rightContainer}>
        <PreviewImage
          formik={formik}
          value={formik.values.image}
          name={formik.values.title}
          description={formik.values.description}
        />
      </Box>
    </Box>
  );
};
export default Minting;
const validationSchema = Yup.object().shape({
  image: Yup.string().required("Please upload an NFT image"),
  title: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  properties: Yup.string().required("Please check one above"),
});
