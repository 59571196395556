import { Box, CircularProgress, InputBase, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useCreateDirectListing,
  useContract,
  useAddress,
  useContractWrite,
  useContractRead,
  useChainId,
} from "@thirdweb-dev/react";

import Button from "../button";

import { useDispatch, useSelector } from "react-redux";
import { putOnFixedPrice } from "../../redux/listing/listing.action";
const FixPriceForm = ({ tokenId, chainId, ownerWallet, amountId }) => {
  const {
    collectionType,
    collectionContract,
    marketplaceContract,
    marketplaceType,
  } = useSelector((state) => state.mint);
  const dispatch = useDispatch();
  const wallet = useAddress();
  const classes = useStyles();
  const chainid = useChainId();
  const { contract: marketplace } = useContract(
    marketplaceContract,
    marketplaceType
  );

  const { contract: nftCollection } = useContract(
    collectionContract,
    collectionType
  );
  const { data } = useContractRead(nftCollection, "isApprovedForAll", [
    wallet,
    marketplaceContract,
  ]);
  const { mutateAsync: setApprovalForAll } = useContractWrite(
    nftCollection,
    "setApprovalForAll"
  );
  const call = async () => {
    try {
      const approvaldata = await setApprovalForAll({
        args: [marketplaceContract, true],
      });
      console.info("contract call successs", approvaldata);
    } catch (err) {
      console.error("contract call failure", err);
    }
  };

  const { mutateAsync: createDirectListing, isLoading } =
    useCreateDirectListing(marketplace);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: "",
      tokenId: tokenId,
      orderId: "",
      transactionHash: "",
      ownerWallet: ownerWallet,
      amountId: amountId,
      chainId: chainId,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      if (!data) {
        call();
      }
      try {
        const date = new Date(Date.now());
        date.setDate(date.getDate() + 365);
        const txResult = await createDirectListing({
          assetContractAddress: collectionContract,
          tokenId: values.tokenId,
          pricePerToken: values.price,
          startTimestamp: new Date(),
          endTimestamp: date,
          overrides: {
            overrides: { gasLimit: 2000000000000000000000 },
          },
        });
        console.log(txResult, "txt");
        const txh = txResult.receipt.transactionHash;
        const orderId = parseInt(txResult.id._hex, 16);
        console.log(orderId, "orderid");
        const payload = {
          orderId: orderId,
          tokenId: values.tokenId,
          amountId: 1,
          chainId: values.chainId,
          transactionHash: txh,
          ownerWallet: ownerWallet,
          price: values.price,
        };
        dispatch(putOnFixedPrice(payload));
      } catch (err) {
        console.log(err.message);
      }
    },
  });

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {" "}
        <Typography
          pb={2}
          sx={{ color: "#fff", fontSize: "20px", fontWeight: 600 }}
        >
          Set a Price
        </Typography>
        <ErrorOutlineIcon sx={{ color: "white", fontSize: "30px" }} />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <InputBase
            sx={{ backgroundColor: "#2A203E !important" }}
            placeholder="eg. Enter your price"
            className={classes.nameInput}
            name="price"
            type="float"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.price && formik.errors.price ? (
            <Typography style={{ color: "red", fontSize: "14px", pl: 2 }}>
              {formik.errors.price}
            </Typography>
          ) : null}
        </Box>
        <Box pt={4}>
          {chainid === chainId ? (
            <Button
              variant="contained"
              type="submit"
              sx={{
                borderRadius: "2px !important",
                background: "rgba(120, 121, 241, 0.33)",
                border: "1.5px solid #7879F1",
                padding: "10px 40px !important",
                width: "100% !important",
              }}
            >
              {isLoading ? (
                <CircularProgress size={"16px"} sx={{ color: "#fff" }} />
              ) : (
                "List Nft"
              )}
            </Button>
          ) : (
            <Typography sx={{ color: "white", fontSize: "18px" }}>
              To list this NFT, Change your chain to{" "}
              <span style={{ color: "red" }}>
                {chainId === 137
                  ? "MATIC"
                  : chainId === 1
                  ? "ETH"
                  : chainId === 8453
                  ? "BASE"
                  : "AVAL"}
              </span>
            </Typography>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default FixPriceForm;
const useStyles = makeStyles((theme) => ({
  nameInput: {
    background: "#1E1C1F",
    color: "#7C7C7C !important",
    fontSize: "18px !important",
    fontWeight: 400 + "!important",
    padding: 10,
    borderRadius: "10px !important",
    width: "100% !important",
  },
}));
const validationSchema = Yup.object().shape({
  price: Yup.string().required("Please enter a fix price"),
});
