import { Box, Button, Typography } from "@mui/material";
import SortDropdown from "../filters/sortDropDown";
import SearchFilter from "../filters/searchFilter";

const ProfileFilters = ({ handleChange, data, setSortedData, setFilter }) => {
  const handleClearFilter = () => {
    setSortedData(data);
    setFilter(data);
  };
  return (
    <Box
      py={3}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: { lg: "nowrap", md: "nowrap", sm: "wrap", xs: "wrap" },
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: { lg: "70%", md: "80%", sm: "100%", xs: "100%" },
          flexWrap: "wrap",
        }}
      >
        <Typography sx={{ fontWeight: 500, fontSize: "20px", color: "#fff  " }}>
          Updated 00 Min ; 00 Sec ago
        </Typography>

        <Button
          sx={{
            color: "#fff",
            textTransform: "capitalize",
            fontSize: "20px",
            fontWeight: 700,
          }}
          onClick={handleClearFilter}
        >
          Clear All
        </Button>
      </Box>
      <Box display="flex" gap="10px">
        {" "}
        <Box>
          {" "}
          <SearchFilter
            handleChange={handleChange}
            placeholder="Enter collection name"
          />
        </Box>
        <Box>
          {" "}
          <SortDropdown data={data} setSortedData={setSortedData} />
        </Box>
      </Box>
    </Box>
  );
};
export default ProfileFilters;
