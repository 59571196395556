import { xNFTs } from "../../http/config";

export const mintApi = async (payload) => {
  try {
    const res = await xNFTs.post("mintArt", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
