import { Box, InputAdornment, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import search from "../../../assets/icons/search.svg";

const SearchFilter = ({ handleChange, placeholder }) => {
  return (
    <Box display="flex" gap="10px" width="100%">
      <InputBase
        placeholder={placeholder}
        onChange={handleChange}
        input={
          <InputBase
            startAdornment={
              <InputAdornment position="start" style={{ marginLeft: "8px" }}>
                <SearchIcon
                  sx={{ color: "#fff", paddingLeft: 10 }}
                  fontSize="large"
                />
              </InputAdornment>
            }
          />
        }
        startAdornment={
          <img
            src={search}
            alt=""
            style={{ marginRight: "12px", width: "25px" }}
          />
        }
        sx={{
          border: "1px solid #fff",
          borderRadius: "5px",
          p: "5px 20px 5px 5px",
          width: "100%",
          color: "rgba(255, 255, 255, 0.45)",
          "&::placeholder": {
            paddingLeft: "20px",
          },
        }}
      />
    </Box>
  );
};

export default SearchFilter;
