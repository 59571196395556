import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import { useTimer } from "react-timer-hook";

const AuctionTimer = ({ expiryTimestamp }) => {
  const classes = useStyles();
  var { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
    format: "24-hour",
  });

  return (
    <div className={classes.root}>
      <LocalFireDepartmentIcon className={classes.fire} />
      <Typography variant="h4" className={classes.time}>
        {days}
      </Typography>
      <span className={classes.colon}>:</span>
      <Typography variant="h4" className={classes.time}>
        {hours}
      </Typography>
      <span className={classes.colon}>:</span>
      <Typography variant="h4" className={classes.time}>
        {minutes}
      </Typography>
      <span className={classes.colon}>:</span>
      <Typography variant="h4" className={classes.time}>
        {seconds}
      </Typography>
    </div>
  );
};

export default AuctionTimer;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    marginTop: theme.spacing(2),
    background: "#0F182E",
    padding: "5px 20px 5px 15px",
    gap: "5px",
    borderRadius: "10px",
  },
  time: {
    margin: theme.spacing(0, 1),
    fontSize: "20px !important",
    fontWeight: 700 + "!important",
  },
  fire: {
    color: "#FF6D00",
    fontSize: "30px !important",
  },
  colon: {
    fontSize: "20px !important",
    fontWeight: 700 + "!important",
  },
}));
