import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useScrollToTop from "../../components/scrollTop";

const LegalDisclaimer = () => {
  const classes = useStyles();
  useScrollToTop();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography variant="h2" className={classes.title}>
            Legal Disclaimer
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography variant="h4" className={classes.subtitle}>
            Welcome to xNFTs.ai!
          </Typography>
          <Typography variant="body1" className={classes.description}>
            By accessing or using our platform, you agree to comply with and be
            bound by the following terms and conditions. Please read them
            carefully.
          </Typography>
          <ol className={classes.list}>
            <li>
              <Typography variant="h6" className={classes.heading}>
                Acceptance of Terms
              </Typography>
              <Typography variant="body1" className={classes.description}>
                By accessing or using xNFTs.ai (the "Platform"), you agree to be
                bound by these Terms of Service and all applicable laws and
                regulations. If you do not agree with any of these terms, you
                are prohibited from using or accessing this site.
              </Typography>
            </li>
            <li>
              <Typography variant="h6" className={classes.heading}>
                Use of the Platform
              </Typography>
              <Typography variant="body1" className={classes.description}>
                xNFTs.ai provides a marketplace for the sale and purchase of
                non-fungible tokens (NFTs) representing digital artwork. Users
                may upload, buy, and sell NFTs on the platform, subject to the
                terms and conditions outlined herein.
              </Typography>
            </li>
            <li>
              <Typography variant="h6" className={classes.heading}>
                Intellectual Property
              </Typography>
              <Typography variant="body1" className={classes.description}>
                Users agree not to upload or sell NFTs that infringe upon the
                intellectual property rights of others. xNFTs.ai respects the
                intellectual property rights of artists and creators and
                prohibits the unauthorized use or reproduction of copyrighted
                material.
              </Typography>
            </li>
            <li>
              <Typography variant="h6" className={classes.heading}>
                Content Guidelines
              </Typography>
              <Typography variant="body1" className={classes.description}>
                xNFTs.ai does not permit the upload or sale of content that
                promotes violence, hate speech, or illegal activities. We
                reserve the right to remove any content that violates our
                content guidelines and to suspend or terminate the accounts of
                users who repeatedly infringe upon these guidelines.
              </Typography>
            </li>
            <li>
              <Typography variant="h6" className={classes.heading}>
                AI Artists
              </Typography>
              <Typography variant="body1" className={classes.description}>
                xNFTs.ai is committed to supporting AI artists from all over the
                world, from emerging talents to professionals. We encourage the
                creation and sale of original artwork generated by AI algorithms
                and promote the ethical use of AI in the creation of digital
                art.
              </Typography>
            </li>
            <li>
              <Typography variant="h6" className={classes.heading}>
                Limitation of Liability
              </Typography>
              <Typography variant="body1" className={classes.description}>
                xNFTs.ai shall not be liable for any direct, indirect,
                incidental, special, or consequential damages arising out of the
                use or inability to use the Platform, even if xNFTs.ai has been
                advised of the possibility of such damages.
              </Typography>
            </li>
            <li>
              <Typography variant="h6" className={classes.heading}>
                Governing Law
              </Typography>
              <Typography variant="body1" className={classes.description}>
                These terms and conditions are governed by and construed in
                accordance with the laws of [jurisdiction], and you irrevocably
                submit to the exclusive jurisdiction of the courts in that
                jurisdiction.
              </Typography>
            </li>
            <li>
              <Typography variant="h6" className={classes.heading}>
                Changes to Terms
              </Typography>
              <Typography variant="body1" className={classes.description}>
                xNFTs.ai reserves the right to revise these terms of service at
                any time without notice. By using this Platform, you are
                agreeing to be bound by the current version of these Terms of
                Service.
              </Typography>
            </li>
            <li>
              <Typography variant="h6" className={classes.heading}>
                Contact Information
              </Typography>
              <Typography variant="body1" className={classes.description}>
                If you have any questions or concerns regarding these terms and
                conditions, please contact us at{" "}
                <a href="/" className={classes.link}>
                  xnftai@gmail.com
                </a>
              </Typography>
            </li>
          </ol>
          <Typography variant="body1" className={classes.endingDescription}>
            By using xNFTs.ai, you acknowledge that you have read, understood,
            and agree to be bound by these terms and conditions.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LegalDisclaimer;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "50px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 0px 30px 0px",
    },
  },
  container: {
    backgroundColor: "#2b2140",
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: 10,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
      paddingTop: "5px",
      paddingBottom: "5px",
      gap: 0,
    },
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
      padding: "10px",
    },
  },
  title: {
    fontSize: "2.5rem",
    color: "white",
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem !important",
      color: "white",
      fontWeight: 700,
    },
  },
  content: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "0px 20px",
    [theme.breakpoints.down("md")]: {
      gap: 5,
    },
  },
  subtitle: {
    fontSize: "1.5rem",
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: "1rem",
    marginBottom: theme.spacing(2),
  },
  list: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontSize: "1.2rem",
    fontWeight: 700,
  },
  endingDescription: {
    fontSize: "1rem",
    fontWeight: 600 + "!important",
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  link: {
    color: "#000",
  },
}));
