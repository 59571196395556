import { Box, Typography } from "@mui/material";
import EditprofileTabs from "../../components/editProfile/editProfileTabs";
import { useStyles } from "./style";
import Notifications from "../../components/notification";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/auth.action";
import { useAddress } from "@thirdweb-dev/react";

const EditProfile = () => {
  const classes = useStyles();
  const wallet = useAddress();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isUpdateInfo, isProfileUpdated, isCoverUpdated } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    if (isUpdateInfo || isProfileUpdated || isCoverUpdated) {
      dispatch(login({ wallet: wallet })).then(() => {
        setTimeout(() => {
          navigate(`/userprofile/${wallet}`);
        }, 3000);
      });
    }
  }, [
    navigate,
    isUpdateInfo,
    isProfileUpdated,
    isCoverUpdated,
    dispatch,
    wallet,
  ]);
  return (
    <Box className={classes.container}>
      <Notifications />
      <Box
        sx={{
          padding: {
            lg: "20px 30px",
            md: "20px 30px",
            sm: "10px 10px",
            xs: "10px 10px",
          },
          backgroundColor: "#13082A",
        }}
      >
        <Typography className={classes.heading}>Profile Setting</Typography>
      </Box>
      <Box sx={{ backgroundColor: "#13082A" }} width={"100%"}>
        <EditprofileTabs />
      </Box>
    </Box>
  );
};
export default EditProfile;
