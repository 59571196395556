import { Box, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bannerDestopImage from "../../assets/images/bannerDestopImage.png";
import bannerMobileImage from "../../assets/images/bannerMobileImage.png";
import Button from "../button";
import { Link } from "react-router-dom";

const Banner = () => {
  const classes = useStyle();
  const isMobile = useMediaQuery("(max-width:899px)");
  const isNotMobile = useMediaQuery("(min-width:900px)");
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.rightContainer}>
        <Box className={classes.headingContainer}>
          <Typography className={classes.heading}>
            Elevate <br /> your collection with exclusive AI-generated{" "}
            <span style={{ color: "#A5A6F6", textDecoration: "underline" }}>
              NFT
            </span>{" "}
            &nbsp; masterpieces.
          </Typography>
        </Box>
        <Box className={classes.titleContainer}>
          <Typography variant="body2" color="white" className={classes.title}>
            Welcome to xNFTs – the future of digital art and creativity. At
            xNFTs, we are pioneering a unique space exclusively for AI-generated
            art, connecting talented AI artists with a global audience of art
            enthusiasts and collectors. Our platform is not just another
            marketplace; it's a vibrant community where innovation meets
            tradition, and where every piece tells a story of computational
            creativity fused with human emotion.
          </Typography>
        </Box>
        {isMobile && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img src={bannerMobileImage} width="100%" alt="" />
          </Box>
        )}
        <Box className={classes.auctionContainer}>
          {/* <Box sx={{ display: "flex", gap: "40px" }}>
            <Box className={classes.divider}>
              <Typography className={classes.inNumber}>10K</Typography>
              <Typography>Artwork</Typography>
            </Box>
            <Box className={classes.divider}>
              <Typography className={classes.inNumber}>32K</Typography>
              <Typography>Auction</Typography>
            </Box>{" "}
            <Box>
              <Typography className={classes.inNumber}>42K</Typography>
              <Typography>Artist</Typography>
            </Box>
          </Box> */}
          <Box className={classes.barnnerAction} pt={3}>
            <Button variant="contained" component={Link} to="/browse">
              Explore
            </Button>
            <Button variant="outlined" component={Link} to="/create">
              Create
            </Button>
          </Box>
        </Box>
      </Box>
      {isNotMobile && (
        <Box>
          <img src={bannerDestopImage} width="100%" alt="" />
        </Box>
      )}
    </Box>
  );
};
export default Banner;
const useStyle = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    padding: "10px 0px",
    alignContent: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      padding: "10px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "0px 0px 0px 0px",
    },
  },

  heading: {
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontWeight: 700 + "!important",
    fontStyle: "normal !important",
    color: "white",
    fontSize: "73px !important",
    lineHeight: "73px !important",

    textTransform: "capitalize !important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "50px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "45px !important",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "38px !important",
      lineHeight: "38px !important",
      textAlign: "start !important",
    },
  },
  headingContainer: {
    width: "695px !important",
    [theme.breakpoints.down("lg")]: {
      width: "580px !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
    },
  },
  titleContainer: {
    width: "695px !important",
    [theme.breakpoints.down("lg")]: {
      width: "550px !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
    },
  },
  title: {
    color: "#9F8FC1 !important",
    fontSize: "20px !important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
  auctionContainer: {
    color: "#fff",
    display: "flex",
    gap: 30,
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      gap: 20,
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      gap: 10,
    },
  },
  inNumber: {
    fontSize: "28px !important",
    fontWeight: 700 + "!important",
    textTransform: "capitalize !important",
    color: "#A5A6F6",
  },
  divider: {
    borderRight: "1px solid #FFFFFF66",
    padding: "0 2rem 0 0",
  },
  barnnerAction: {
    display: "flex",
    alignItems: "center",
    gap: 30,
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "space-between",
      gap: 70,
    },
  },
  rightContainer: {
    padding: "30px 10px ",
    display: "flex",
    flexDirection: "column",
    gap: 30,
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      width: "50%",
      gap: 30,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center !important",
      padding: "20px 0px",
      gap: 25,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center !important",
      padding: "10px 0px",
      gap: 10,
    },
  },
}));
