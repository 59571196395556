import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import { Box, Button, CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import AuctionTimer from "../../timer";
import { profileBaseURL } from "../../../http/config";
import image1 from "../../../assets/images/noprofile.jpg";
import { useContract, useEnglishAuction } from "@thirdweb-dev/react-core";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ShortString } from "../../../utils";
const SingleCard = ({
  fixedPrice,
  image,
  creatorUsername,
  chainId,
  title,
  creatorImg,
  amountId,
  tokenId,
  onAuction,
  reservePrice,
  highestBid,
  auctionId,
}) => {
  const { marketplaceContract, marketplaceType } = useSelector(
    (state) => state.mint
  );
  const [time, setTime] = useState(0);
  const classes = useStyles();
  const { contract } = useContract(marketplaceContract, marketplaceType);
  const { data: englishAuction, isLoading } = useEnglishAuction(
    contract,
    auctionId
  );
  useEffect(() => {
    var date = 0;
    if (onAuction && !isLoading) {
      date = new Date(englishAuction?.endTimeInSeconds * 1000);

      setTime(date);
    }
  }, [onAuction, isLoading, englishAuction]);
  let owner = creatorUsername;
  let creator =
    owner?.length > 12 ? `${owner.substr(0, 4)}...${owner.substr(-4)}` : owner;
  const extension = image?.split(".").pop();

  return (
    <Card className={`${classes.card} ${classes.cardHovered}`}>
      <CardActionArea className={classes.CardActionArea}>
        <Box>
          {" "}
          <Box>
            {" "}
            <Link to={`/${tokenId}/${amountId}/${chainId}`}>
              {extension === "mp4" ||
              extension === "webm" ||
              extension === "mov" ||
              extension === "mpeg" ||
              extension === "quicktime" ||
              extension === "x-msvideo" ||
              extension === "x-ms-wmv" ? (
                <video
                  src={image}
                  className={classes.cardMedia}
                  width="100%"
                  height="200"
                  controls
                  autoplay
                  playsinline
                />
              ) : (
                <CardMedia
                  component="img"
                  image={image}
                  width="200"
                  height="200"
                  alt="nft image"
                  className={classes.cardMedia}
                />
              )}
            </Link>
          </Box>
          {onAuction === 1 && highestBid !== 0 && (
            <Box className={classes.auctionTimer}>
              {time !== 0 && <AuctionTimer expiryTimestamp={time} />}
            </Box>
          )}
        </Box>
        <CardContent className={classes.cardContent}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography gutterBottom variant="h6">
              {title}
            </Typography>
            <Button
              variant="contained"
              size="small"
              disableRipple
              sx={{ backgroundColor: "#1E50FF", borderRadius: 3 }}
            >
              {chainId === 137
                ? "MATIC"
                : chainId === 1
                ? "ETH"
                : chainId === 8453
                ? "BASE"
                : "AVAL"}
            </Button>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Box>
                <img
                  src={
                    creatorImg === null ? image1 : profileBaseURL + creatorImg
                  }
                  style={{ borderRadius: 15 }}
                  width="56px"
                  height="56px"
                  alt=""
                />
              </Box>
              <Box>
                <Typography variant="caption" color="#8F9CA9">
                  Creator
                </Typography>
                <Typography variant="body1">
                  {" "}
                  <ShortString address={creator} />{" "}
                </Typography>
              </Box>
            </Stack>
            <Box>
              {onAuction === 1 ? (
                <Typography variant="caption" color="#8F9CA9">
                  Current Bid
                </Typography>
              ) : (
                <Typography variant="caption" color="#8F9CA9">
                  Fix Price
                </Typography>
              )}
              {onAuction === 1 ? (
                <Typography variant="body1">
                  {reservePrice}&nbsp;
                  {chainId === 137
                    ? "MATIC"
                    : chainId === 1
                    ? "ETH"
                    : chainId === 8453
                    ? "BASE"
                    : "AVAL"}
                </Typography>
              ) : (
                <Typography variant="body1">
                  {fixedPrice}&nbsp;{" "}
                  {chainId === 137
                    ? "MATIC"
                    : chainId === 1
                    ? "ETH"
                    : chainId === 8453
                    ? "BASE"
                    : "AVAL"}
                </Typography>
              )}
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SingleCard;
const useStyles = makeStyles((theme) => ({
  card: {
    padding: "1.5rem 1.5rem 0.5rem !important",
    borderRadius: "1.5rem !important",
    backgroundColor: "#FFFFFF1A !important",
    color: "white !important",
    overflow: "hidden !important",
    transition: "height 3s !important",
    width: "30vw !important",

    [theme.breakpoints.down("md")]: {
      width: "40vw !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80vw  !important",
    },
  },

  CardActionArea: {
    position: "relative",
  },

  cardMedia: {
    borderRadius: "1.5rem",
    aspectRatio: "3/2",
    objectFit: "cover !important",
    minHeight: "40vh !important",
    overflow: "hidden !important",
    position: "relative !important",
    [theme.breakpoints.down("lg")]: {
      // minHeight: "45vh !important",
    },
    [theme.breakpoints.down("md")]: {
      // minHeight: "50vh !important",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "34vh !important",
    },
  },
  cardContent: {
    padding: "20px 10px 10px !important",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  cardIcon: {
    color: "white !important",
    padding: 0 + "!important",
    marginLeft: 5,
  },
  cardActions: {
    paddingTop: 10,
    display: "flex !important",
    justifyContent: "space-between",
    padding: "10px 0 !important",
  },
  button: {
    width: "100% !important",
    padding: "0px !important",
    height: "35px !important",
    fontWeight: "600 !important",
  },
  auctionTimer: {
    position: "absolute",
    top: "65%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
}));
