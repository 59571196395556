import { Box } from "@mui/material";
import ArtWorkHistory from "../../components/artworkHistory";
import CardDetail from "../../components/cardDetail";
import Slider from "../../components/Slider";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getAllArts,
  getArtHistory,
  getSingleArts,
} from "../../redux/arts/arts.action";
import Notifications from "../../components/notification";
import { useAddress } from "@thirdweb-dev/react";
import { inWallet } from "../../redux/profile/profile.action";
import useScrollToTop from "../../components/scrollTop";

const ArtDetails = () => {
  const [bidSubmit, setSubmitting] = useState();
  const [fixPriceSubmit, setFixedPrice] = useState();
  const { tokenId, amountId, chainId } = useParams();
  const address = useAddress();
  const dispatch = useDispatch();
  useScrollToTop();
  useEffect(() => {
    let payload = {
      tokenId,
      amountId,
      chainId,
    };
    dispatch(getAllArts());
    dispatch(getSingleArts(payload));
    dispatch(getArtHistory(payload));
    dispatch(inWallet(address));
  }, [dispatch, tokenId, amountId, chainId, address]);
  const { singleArts } = useSelector((state) => state.arts);
  const { isInWalletSuccess } = useSelector((state) => state.profile);
  const handleClick = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };
  return (
    <>
      <Notifications bidSubmit={bidSubmit} fixPriceSubmit={fixPriceSubmit} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            lg: "1fr 1fr",
            md: "1fr 1fr",
            sm: "1fr",
            xs: "1fr",
          },
          padding: {
            lg: "20px 20px",
            md: "10px 10px",
            sm: "10px 10px",
            xs: "10px 10px",
          },
        }}
      >
        <CardDetail
          {...singleArts}
          setSubmitting={setSubmitting}
          setFixedPrice={setFixedPrice}
          fixPriceSubmit={fixPriceSubmit}
          bidSubmit={bidSubmit}
        />
      </Box>
      <Box
        sx={{
          padding: {
            lg: "20px 20px",
            md: "10px 10px",
            sm: "10px 10px",
            xs: "10px 10px",
          },
        }}
      >
        <ArtWorkHistory />;
      </Box>
      {isInWalletSuccess && (
        <Box onClick={handleClick}>
          <Slider title="Created NFTs" btnLabel="View More" />
        </Box>
      )}
    </>
  );
};

export default ArtDetails;
