import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  image_video: {
    minWidth: "550px",
    maxWidth: "550px",
    maxHeight: "600px",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
      maxWidth: "100%",
      maxHeight: "600px",
    },
  },
}));
