import { useState } from "react";
import { InputAdornment, InputBase, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SortDropdown = ({ data, setSortedData, sortingOptions }) => {
  const [selectedOption, setSelectedOption] = useState(sortingOptions[0].value);
  const handleSortChange = (sortBy) => {
    let sortedResults = [...data];
    const sortFunction = sortingOptions.find(
      (option) => option.value === sortBy
    )?.sortFunction;

    if (sortFunction) {
      sortedResults.sort(sortFunction);
      setSortedData(sortedResults);
    }
  };

  const handleChange = (e) => {
    const newSelectedOption = e.target.value;
    setSelectedOption(newSelectedOption);
    handleSortChange(newSelectedOption);
  };

  return (
    <Select
      id="dropdown-menu"
      onChange={handleChange}
      value={selectedOption}
      input={
        <InputBase
          startAdornment={<InputAdornment position="start"></InputAdornment>}
        />
      }
      sx={{
        p: "5px 20px",
        background: "transparent",
        color: "#fff",
        border: "1px solid #fff",
        borderRadius: "5px",
        ".MuiSelect-icon": { color: "#fff" },
      }}
      IconComponent={KeyboardArrowDownIcon}
    >
      {sortingOptions.map((option, index) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={index === 0}
        >
          {index === 0 ? option.defaultLabel : option.label}{" "}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SortDropdown;
