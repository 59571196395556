import React, { useEffect } from "react";
import {
  useAddress,
  useChainId,
  useConnectionStatus,
  useDisconnect,
  useSwitchChain,
} from "@thirdweb-dev/react";
import { login } from "../../redux/auth/auth.action";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCollectionContract,
  changeMarketplaceContract,
} from "../../redux/mint/mint.action";
import { resetStateAuth } from "../../redux/auth/auth.slicer";
const WalletConnect = ({ children }) => {
  const connectionStatus = useConnectionStatus();
  const switchChain = useSwitchChain();
  const wallet = useAddress();
  const disconnect = useDisconnect();
  const dispatch = useDispatch();
  const connectedChainId = useChainId();
  const { network } = useSelector((state) => state.mint);
  const selectedChain = JSON.parse(network);
  console.log(selectedChain.chain);
  useEffect(() => {
    if (selectedChain.chain === "ETH") {
      dispatch(
        changeCollectionContract("0xc8D4e70eb9450B8c409b199a74cCa29D0EfecCF5")
      );
      dispatch(
        changeMarketplaceContract("0x40c29C10D255651cdbcaf00Df95a23B8E5A1d2d8")
      );
    }
    if (selectedChain.chain === "AVAX") {
      dispatch(
        changeCollectionContract("0x5221298c5aeb0a281d6645fd87368412cE0F0e08")
      );
      dispatch(
        changeMarketplaceContract("0xe1C7176Ec7A6A422979B59B9524Ed422e1039DD7")
      );
    }
    if (selectedChain.chain === "Polygon") {
      dispatch(
        changeCollectionContract("0x07b7F57b6eF32EE8496fE38Bf34e3eF7A458b3e5")
      );
      dispatch(
        changeMarketplaceContract("0x8bb47fec5419914971347551Af766e3993054167")
      );
    }
    if (connectionStatus === "connected") {
      if (connectedChainId !== selectedChain.chainId) {
        switchChain(selectedChain.chainId)
          .then(() => {
            dispatch(login({ wallet: wallet }));
          })
          .catch(() => {
            disconnect();
            dispatch(resetStateAuth());
          });
      } else {
        dispatch(login({ wallet: wallet }));
      }
    }
  }, [
    connectionStatus,
    dispatch,
    wallet,
    switchChain,
    disconnect,
    connectedChainId,
    selectedChain,
  ]);
  return <>{children}</>;
};

export default WalletConnect;
