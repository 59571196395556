import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginApi, searchUserApi } from "./api";

export const login = createAsyncThunk("auth/login", async (payload) => {
  try {
    const res = await loginApi(payload);
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const searchUser = createAsyncThunk("auth/search", async (payload) => {
  try {
    const res = await searchUserApi(payload);
    return res.data;
  } catch (error) {
    throw error;
  }
});
