import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allCreatorApi,
  checkEmailApi,
  checkUsernameApi,
  getSingleCreatorApi,
  inWalletApi,
  updateUserInfoApi,
  uploadCoverApi,
  uploadProfileApi,
} from "./api";

export const checkEmail = createAsyncThunk(
  "profile/checkemail",
  async (payload) => {
    try {
      const res = await checkEmailApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const checkUsername = createAsyncThunk(
  "profile/checkusername",
  async (payload) => {
    try {
      const res = await checkUsernameApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const uploadProfile = createAsyncThunk(
  "profile/uploadProfile",
  async (payload) => {
    try {
      const res = await uploadProfileApi(payload);
      return res.data.message;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
export const uploadCover = createAsyncThunk(
  "profile/uploadCover",
  async (payload) => {
    try {
      const res = await uploadCoverApi(payload);
      return res.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
export const updateInfo = createAsyncThunk(
  "profile/updateInfo",
  async (payload) => {
    try {
      const res = await updateUserInfoApi(payload);
      return res.data.message;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
export const inWallet = createAsyncThunk(
  "profile/inWallet",
  async (payload) => {
    try {
      const res = await inWalletApi(payload);
      return res.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
export const allCreators = createAsyncThunk(
  "profile/allCreators",
  async (payload) => {
    try {
      const res = await allCreatorApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

//////////////////////////////////////////
export const singleCreator = createAsyncThunk(
  "profile/singleCreator",
  async (payload) => {
    try {
      const res = await getSingleCreatorApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
