import { Box, InputBase, Typography } from "@mui/material";
import XIcon from "@mui/icons-material/X";
// import verify from "../../../assets/images/verifyIcon.png";
import Button from "../../button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  checkEmail,
  checkUsername,
} from "../../../redux/profile/profile.action";

const UserInformation = ({ formik }) => {
  const [isTouched, setIsTouched] = useState(false);
  const [isUsernameTouched, setIsUsernameTouched] = useState(false);
  const dispatch = useDispatch();
  const {
    isUsernameExists,
    isEmailExists,
    isEmailExistLoadingFailed,
    isUsernameExistsFailed,
  } = useSelector((state) => state.profile);

  useEffect(() => {
    if (isUsernameTouched && formik.values.username) {
      dispatch(checkUsername(formik.values.username));
    }
  }, [dispatch, formik.values.username, isUsernameTouched]);
  useEffect(() => {
    if (isTouched && formik.values.email) {
      dispatch(checkEmail(formik.values.email));
    }
  }, [dispatch, formik.values.email, isTouched]);
  return (
    <Box
      pt={4}
      sx={{
        display: "grid",
        gridTemplateColumns: {
          lg: "1fr 1fr",
          md: "1fr 1fr",
          sm: "1fr ",
          xs: "1fr",
        },
        gap: "30px",
        padding: {
          lg: "20px 0px",
          md: "20px 0px",
          sm: "30px",
          xs: "30px",
        },
      }}
    >
      <Box>
        <Box>
          <Typography py={1} sx={{ fontSize: "22px", fontWeight: 700 }}>
            Display Name
          </Typography>
          <InputBase
            placeholder="enter your name"
            name="displayName"
            value={formik.values.displayName}
            onChange={formik.handleChange}
            sx={{
              color: "#ffff ",
              opacity: 40,
              background: "#2B213F",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              fontSize: "14px",
              fontWeight: 400,
            }}
          />
        </Box>
        <Box>
          <Typography py={1} sx={{ fontSize: "22px", fontWeight: 700 }}>
            Username
          </Typography>
          <InputBase
            placeholder="@   enter your username "
            name="username"
            value={formik.values.username}
            onChange={(e) => {
              formik.handleChange(e);
              setIsUsernameTouched(true);
            }}
            sx={{
              border: "",
              color: "#ffff ",

              background: "#2B213F",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              fontSize: "14px",
              fontWeight: 400,
            }}
          />
          {isUsernameTouched && formik.values.username && (
            <>
              {isUsernameExists ? (
                <Typography
                  sx={{ color: "green", fontSize: "14px", paddingLeft: 0.5 }}
                >
                  The username is available for use.
                </Typography>
              ) : isUsernameExistsFailed ? (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "14px",
                    padding: 0.5,
                  }}
                >
                  Username is already in use.
                  <span style={{ color: "green" }}>
                    {" "}
                    Please choose a different email address.
                  </span>
                </Typography>
              ) : null}
            </>
          )}
          <Typography
            py={1}
            sx={{
              fontSize: "13px",
              fontWeight: 500,
              color: "rgba(255, 255, 255, 0.60)",
            }}
          >
            Your profile will be available on rarible.com/[username]
          </Typography>
        </Box>{" "}
        <Box>
          <Typography sx={{ fontSize: "22px", fontWeight: 700 }}>
            Email
          </Typography>
          <InputBase
            placeholder="@   enter your email address"
            name="email"
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
              setIsTouched(true);
            }}
            sx={{
              border: "",
              color: "#ffff ",

              background: "#2B213F",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              fontSize: "14px",
              fontWeight: 400,
            }}
          />
          {isTouched && formik.values.email && (
            <>
              {isEmailExists ? (
                <Typography
                  sx={{ color: "green", fontSize: "14px", paddingLeft: 0.5 }}
                >
                  The email is available for use.
                </Typography>
              ) : isEmailExistLoadingFailed ? (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "14px",
                    padding: 0.5,
                  }}
                >
                  Email is already in use.
                  <span style={{ color: "green" }}>
                    {" "}
                    Please choose a different email address.
                  </span>
                </Typography>
              ) : null}
            </>
          )}

          <Typography
            py={1}
            sx={{
              fontSize: "13px",
              fontWeight: 500,
              color: "rgba(255, 255, 255, 0.60)",
            }}
          ></Typography>
        </Box>
        <Box>
          <Typography py={1} sx={{ fontSize: "22px", fontWeight: 700 }}>
            Short Bio
          </Typography>
          <InputBase
            placeholder="Tell about your self in a few words..."
            name="bio"
            value={formik.values.bio}
            onChange={formik.handleChange}
            sx={{
              border: "",
              color: "#ffff ",
              opacity: 40,
              background: "#2B213F",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              fontSize: "14px",
              fontWeight: 400,
            }}
          />
        </Box>
        <Box>
          <Typography py={1} sx={{ fontSize: "22px", fontWeight: 700 }}>
            Social Links
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 500,
              color: "rgba(255, 255, 255, 0.60)",
            }}
          >
            Add your existing social links to build a stronger reputation
          </Typography>
        </Box>
        <Box>
          <Typography py={1} sx={{ fontSize: "15px", fontWeight: 500 }}>
            Instagram
          </Typography>
          <InputBase
            placeholder="@"
            name="instagram"
            value={formik.values.instagram}
            onChange={formik.handleChange}
            sx={{
              border: "",
              color: "#ffff ",
              opacity: 40,
              background: "#2B213F",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              fontSize: "14px",
              fontWeight: 400,
            }}
          />
        </Box>{" "}
        <Box>
          <Typography py={1} sx={{ fontSize: "15px", fontWeight: 700 }}>
            X (Twitter)
          </Typography>
          <InputBase
            placeholder="Enter your X username"
            name="twitter"
            value={formik.values.twitter}
            onChange={formik.handleChange}
            startAdornment={
              <XIcon
                sx={{
                  color: "#ffff ",
                  opacity: 40,
                  fontSize: "14px",
                  marginRight: "5px",
                }}
              />
            }
            endAdornment={
              <span style={{ color: "#fff", fontWeight: "bold" }}>Link</span>
            }
            sx={{
              border: "",
              color: "#ffff ",
              opacity: 40,
              background: "#2B213F",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              fontSize: "14px",
              fontWeight: 400,
              "&::placeholder": {
                paddingLeft: "10px",
              },
            }}
          />
        </Box>
        <Box pt={2.5}>
          <Button
            sx={{ backgroundColor: "#7879F1" }}
            onClick={formik.handleSubmit}
            variant="contained"
          >
            Save settings
          </Button>
        </Box>
      </Box>
      {/* <Box
        sx={{
          background: "rgba(255, 255, 255, 0.10)",
          padding: "20px",
          maxWidth: "220px",
          display: { lg: "flex", md: "none", sm: "none", xs: "none" },
          flexDirection: "column",
          borderRadius: "10px",
          maxHeight: "300px",
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <img src={verify} width="100%" alt="" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Box>
            {" "}
            <Typography pt={0.5} sx={{ fontSize: "18px", fontWeight: 600 }}>
              Verify your account
            </Typography>
            <Typography
              pt={0.5}
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.60)",
              }}
            >
              Proceed with verification process to get more visibility and gain
              trust on Rarible
            </Typography>
          </Box>
          <Button
            pt={2}
            variant="outlined"
            sx={{ borderRadius: "5px !important" }}
          >
            Get Verified
          </Button>
        </Box>
      </Box> */}
    </Box>
  );
};

export default UserInformation;
