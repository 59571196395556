import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableHeadRow from "../TableHead";
import TableBodyRow from "../TableBody";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  table: {
    padding: "0 1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem",
    },
  },
  [theme.breakpoints.up("md")]: {
    scroller: {
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        backgroundColor: theme.palette.background.paper,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        },
      },
    },
  },
}));

const TrendingCollectionTable = ({ tableColumnData, tableRowsData }) => {
  const classes = useStyles();
  const { bitCoinData } = useSelector((state) => state.arts);
  return (
    <TableContainer
      sx={{ maxHeight: { lg: 600, md: 600, sm: 500, xs: 470 } }}
      className={classes.scroller}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        size="large"
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            {tableColumnData.map((column) => (
              <TableHeadRow key={column.id} {...column} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {bitCoinData.map((row) => (
            <TableBodyRow key={row.id} {...row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TrendingCollectionTable;
