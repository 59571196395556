import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "linear-gradient(to right, #210f47c4, #13082f)",
    color: "#FFFFFF !important",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: "60px 30px",
    gap: "40px",

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      padding: "0px 0px 45px 0",
      gap: "200px !important",
    },
  },
  leftContainer: {
    display: "flex !important",
    flexDirection: "column",
    gap: 20,
    flex: 1,
    [theme.breakpoints.down("md")]: {
      // width: "100vw !important",
      alignItems: "center",
      gap: 20,
    },
  },
  container: {
    display: "flex !important",
    flexDirection: "column",
    gap: 20,
    [theme.breakpoints.down("md")]: {
      width: "90vw !important",
    },
  },
  nameInput: {
    background: "#1E1C1F",
    color: "#7C7C7C !important",
    fontSize: "18px !important",
    fontWeight: "400 !important",
    padding: 10,
    borderRadius: "10px !important",
    width: "100% !important",
  },

  description: {
    color: "#848484",
    fontSize: "10px !important",
    padding: "0px 0 5px 0px !important",
  },
  textArea: {
    background: "#2A203E",
    color: "#7C7C7C !important",
    fontSize: "16px !important",
    fontWeight: 400 + "!important",
    padding: "10px 15px",
    borderRadius: "10px !important",
    width: "100% !important",
    border: "none !important",
    "&::placeholder": {},
  },
  propertiesContainer: {
    background: "#2A203E",
    borderRadius: "10px !important",
    padding: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    background: "#504761",
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  rightContainer: {
    display: "flex",
    justifyContent: "center",
    minHeight: "360px",
    maxHeight: "360px",

    [theme.breakpoints.down("md")]: {
      order: -1,
      alignItems: "start",
      paddingTop: "20px",
      minHeight: "360px",
      maxHeight: "360px",
      paddingBottom: 20 + "!important",
    },
  },
  button: {
    width: "150px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 400 + "!important",
    paddingBottom: "10px",
    "& span": {
      color: "#AF0A0A",
    },
  },
  details: {
    padding: "20px 0 20px 0 !important",
  },
}));
