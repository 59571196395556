import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";

const NetworkTable = () => {
  const { artHistory } = useSelector((state) => state.arts);
  const classes = useStyles();

  return (
    <TableContainer
      sx={{ padding: "15px", background: "#261C3B", borderRadius: "5px" }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          sx={{ borderRadius: "5px !important", backgroundColor: "#0A080D" }}
        >
          <TableRow>
            <TableCell className={classes.tableHeader}>Activity</TableCell>
            <TableCell className={classes.tableHeader}>PRICE</TableCell>
            <TableCell className={classes.tableHeader}>
              Transaction Hash
            </TableCell>
            <TableCell className={classes.tableHeader}>FROM</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {artHistory.map(
            ({ walletAddress, price, type, transactionHash, chainId }) => (
              <>
                <TableRow className={classes.tableRow}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableCell}
                  >
                    {type}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {price}{" "}
                    {chainId === 137
                      ? "MATIC"
                      : chainId === 1
                      ? "ETH"
                      : chainId === 8453
                      ? "BASE"
                      : "AVAL"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {transactionHash}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {walletAddress}
                  </TableCell>
                </TableRow>
              </>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NetworkTable;
const useStyles = makeStyles({
  table: {
    minWidth: 650,

    padding: "20px !important",
  },
  tableCell: {
    padding: "8px 16px",
    color: "white !important",
    border: "none !important",
    backgroundColor: "#24182F",
    textTransform: "capitalize",
  },
  tableHeader: {
    fontWeight: "bold",
    color: "white !important",
    border: "none !important",
  },
  tableRow: {
    height: "48px",
    color: "white !important",
    padding: "10px 0",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    borderBottom: "1px solid grey",
  },
});
