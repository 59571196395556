import axios from "axios";
import { xNFTs } from "../../http/config";

//Get All Arts
export const getAllArtsApi = async (payload) => {
  try {
    const res = await xNFTs.get("getArts", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
// get single Arts
export const getSingleArtsApi = async ({ tokenId, amountId, chainId }) => {
  try {
    const res = await xNFTs.get(`singleArt/${tokenId}/${amountId}/${chainId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//Art History API

export const getArtHistoryApi = async ({ tokenId, amountId, chainId }) => {
  try {
    const res = await xNFTs.get(`nftHistory/${tokenId}/${amountId}/${chainId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
// Bit coin actuall Data

export const getBitcoinDataApi = async (payload) => {
  try {
    const res = await axios.get(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false",
      payload
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};
