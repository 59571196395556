import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

export default function MouseOverPopover({
  handlePopoverOpen,
  handlePopoverClose,
  anchorEl,
  label,
  message,
}) {
  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        variant="body1"
        color="#FFF"
        sx={{ cursor: "pointer" }}
      >
        {label}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          position: "absolute",
          top: 5,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 0.5, fontSize: "14px" }}>
          {message
            ? "You are not approved for minting from admin"
            : "   Connect wallet first"}
        </Typography>
      </Popover>
    </div>
  );
}
