import { Box } from "@mui/material";
import ListingDetails from "../../components/lsitingDetail";
import { useDispatch, useSelector } from "react-redux";
import { getSingleArts } from "../../redux/arts/arts.action";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Notifications from "../../components/notification";
import useScrollToTop from "../../components/scrollTop";

const Listing = () => {
  const { isPutOnFixPriceSuccess, isPutOnAuctionSuccess } = useSelector(
    (state) => state.putOnFixPrice
  );
  const { tokenId, amountId, chainId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useScrollToTop();
  useEffect(() => {
    let payload = {
      tokenId,
      amountId,
      chainId,
    };
    dispatch(getSingleArts(payload));
  }, [dispatch, tokenId, amountId, chainId]);
  useEffect(() => {
    if (isPutOnFixPriceSuccess || isPutOnAuctionSuccess) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, [isPutOnFixPriceSuccess, isPutOnAuctionSuccess, navigate]);
  return (
    <>
      <Notifications />
      <Box>
        <ListingDetails />
      </Box>
    </>
  );
};

export default Listing;
