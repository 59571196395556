import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Button from "../../button";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { uploadCover } from "../../../redux/profile/profile.action";
import { coverBaseURL } from "../../../http/config";
import coverImage from "../../../assets/images/profileCoverImage.png";
const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "SVG"];

function EditCoverImage() {
  const dispatch = useDispatch();
  const {
    user: { walletAddress, cover },
  } = useSelector((state) => state.auth);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      wallet: "",
      cover: "",
    },
  });
  const handleChange = (file) => {
    if (file) {
      formik.setFieldValue("profile", file);
      const reader = new FileReader();
      reader.onload = () => {
        const fd = new FormData();
        fd.append("cover", file);
        fd.append("wallet", walletAddress);
        dispatch(uploadCover(fd));
      };
      reader.readAsDataURL(file);
    }
  };
  const classes = useStyles();
  return (
    <FileUploader handleChange={handleChange} name="file" types={fileTypes}>
      <div className={classes.upload}>
        {!formik.values.cover ? (
          <div className={classes.imageContainer}>
            <img
              src={
                coverBaseURL + cover === null
                  ? coverImage
                  : coverBaseURL + cover
              }
              className={classes.coverImage}
              alt="coverImage"
            />
          </div>
        ) : (
          <div className={classes.image}>
            <img
              src={
                formik.values.cover && URL.createObjectURL(formik.values.cover)
              }
              className={classes.coverImage}
              alt="nft"
            />
          </div>
        )}
      </div>
      <Box className={classes.buttonContainer}>
        <Button variant="contained" startIcon={<CameraAltIcon />}>
          Edit Cover
        </Button>
      </Box>
    </FileUploader>
  );
}

export default EditCoverImage;

const useStyles = makeStyles((theme) => ({
  upload: {
    cursor: "pointer",
    maxWidth: "100%",
  },
  icon: {
    color: theme.palette.text.primary,
  },

  helperText: {
    marginTop: 2,
    fontSize: "1rem",
  },
  error: {
    color: "red",
  },
  buttonContainer: {
    position: "absolute",
    bottom: 15,
    right: "12%",

    [theme.breakpoints.down("md")]: {
      right: "2%",
    },
    "& button": {
      [theme.breakpoints.down("md")]: {
        fontSize: "12px !important",
      },
    },
  },
  coverImage: {
    maxWidth: "90%",
    minWidth: "90%",
    maxHeight: "300px",
    minHeight: "300px",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      maxHeight: "150px",
      minHeight: "150px",
      maxWidth: "100%",
      minWidth: "100%",
      objectFit: "cover",
    },
  },
}));
