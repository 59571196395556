import { RouterProvider } from "react-router-dom";
import theme from "./theme";
import router from "./routes";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { Polygon, Ethereum, Avalanche, Base } from "@thirdweb-dev/chains";
import { ThemeProvider } from "@mui/styles";
import WalletConnect from "./hooks/thirdwebHooks/WalletConnect";
import RocketFired from "./components/scrollUp";
import { useSelector } from "react-redux";

function App() {
  const { network } = useSelector((state) => state.mint);
  return (
    <ThemeProvider theme={theme}>
      <ThirdwebProvider
        supportedChains={[Polygon, Ethereum, Avalanche, Base]}
        clientId={"a2b779af9c0c2715c4b24a765d2968c4"}
        activeChain={JSON.parse(network)}
      >
        <WalletConnect>
          <RouterProvider router={router} />
          <RocketFired />
        </WalletConnect>
      </ThirdwebProvider>
    </ThemeProvider>
  );
}

export default App;
