import React from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { data } from "./data";

const MapCard = () => {
  return (
    <Timeline position="alternate">
      {data &&
        data.map(
          (
            {
              phase,
              title,
              primary: { heading, description, headingTwo, descriptionTwo },
              id,
            },
            index
          ) => (
            <React.Fragment key={id} style={{ width: "380px" }}>
              {index % 2 === 0 ? (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      sx={{
                        "&.MuiTimelineDot-root": {
                          backgroundColor: "#7879F1",
                        },
                      }}
                    />
                    <TimelineConnector
                      sx={{
                        "&.MuiTimelineConnector-root": {
                          background: "#7879F1",
                        },
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          background: "rgba(255, 255, 255, 0.07)",
                          border: "1.284px solid #FFF",
                          borderRadius: "10px",
                          padding: "10px 20px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                          width: {
                            lg: "429px",
                            md: "429px",
                            sm: "300px",
                            xs: "240px",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#7879F1",
                            fontSize: "19px",
                            fontWeight: 700,
                          }}
                        >
                          {title}
                        </Typography>
                        <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "17px",
                              fontWeight: 700,
                            }}
                          >
                            {heading}:
                          </span>{" "}
                          {description}
                        </Typography>
                        <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "17px",
                              fontWeight: 700,
                            }}
                          >
                            {headingTwo}
                          </span>{" "}
                          {descriptionTwo}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          background:
                            "linear-gradient(205deg, #7879F1 36.59%, #4243AF 114.36%);",
                          padding: "10px",
                          borderRadius: "0px 50px 50px 0px",
                          display: "flex",
                          alignItems: "center",
                          height: "50px",
                          position: "relative",
                          top: 15,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: 700,
                            color: "#fff",
                            width: "80px",
                          }}
                        >
                          {phase}
                        </Typography>
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              ) : (
                <TimelineItem>
                  <TimelineSeparator
                    sx={{
                      background:
                        "var(--Linear, linear-gradient(99deg, #FF50C5 1.24%, #0C4381 99.12%))",
                    }}
                  >
                    {/* <TimelineDot /> */}
                    <TimelineConnector
                      sx={{
                        "&.MuiTimelineConnector-root": {
                          background: "#7879F1",
                        },
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          background:
                            "linear-gradient(205deg, #7879F1 36.59%, #4243AF 114.36%);",
                          padding: "10px",
                          borderRadius: "50px 0px 0px 50px",
                          display: "flex",
                          alignItems: "center",
                          height: "50px",
                          position: "relative",
                          top: 15,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: 700,
                            color: "#fff",
                            width: "80px",
                          }}
                        >
                          {phase}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          background: "rgba(255, 255, 255, 0.07)",
                          border: "1.284px solid #FFF",
                          borderRadius: "10px",
                          padding: "10px 20px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                          width: {
                            lg: "429px",
                            md: "429px",
                            sm: "300px",
                            xs: "240px",
                          },
                          textAlign: "start",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#7879F1",
                            fontSize: "19px",
                            fontWeight: 700,
                          }}
                        >
                          {title}
                        </Typography>
                        <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "17px",
                              fontWeight: 700,
                            }}
                          >
                            {heading}:
                          </span>{" "}
                          {description}
                        </Typography>
                        <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "17px",
                              fontWeight: 700,
                            }}
                          >
                            {heading}
                          </span>{" "}
                          {description}
                        </Typography>
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              )}
            </React.Fragment>
          )
        )}
    </Timeline>
  );
};

export default MapCard;
