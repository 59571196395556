import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import Carousel from "react-elastic-carousel";
import { CarouselArrow } from "../../utils";
import SingleCard from "../card/singleCard";
import Wrapper from "../wrapper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const Slider = ({ title, btnLabel }) => {
  const {
    inWallet: { createdArts },
  } = useSelector((state) => state.profile);
  const isMobileView = useMediaQuery("(max-width:600px)");
  const isNotMobile = useMediaQuery("(min-width:600px)");
  const isMobileCarousel = useMediaQuery("(max-width:600px)");
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 700, itemsToShow: 2, itemsToScroll: 2, itemPadding: [0, 10] },
    { width: 1150, itemsToShow: 3, itemsToScroll: 3, itemPadding: [0, 20] },
    { width: 1200, itemsToShow: 3, itemPadding: [0, 20] },
  ];
  return (
    <Box py={{ lg: 6, md: 4, sm: 2, xs: 1 }}>
      <Wrapper>
        <Box
          sx={{
            display: "flex",
            justifyContent: isMobileView ? "center" : "space-between",
            alignItems: "center",
            padding: {
              md: "2rem 0",
              sm: "1rem 0",
            },
            paddingBottom: { sm: "1rem" },
          }}
        >
          {title && (
            <Typography
              variant="h6"
              sx={{
                color: "white",
                fontSize: { lg: "36px", md: "32px", sm: "27px" },
                fontWeight: 700,
                width: "fit-content",
              }}
            >
              {title}
            </Typography>
          )}
          {isNotMobile && btnLabel && (
            <Button
              variant="outlined"
              disableRipple
              size="medium"
              sx={{
                color: "white",
                outlineColor: "#7879F1",
                borderColor: "#7879F1",
              }}
              component={Link}
              to="/browse"
            >
              {btnLabel}
            </Button>
          )}
        </Box>
      </Wrapper>

      <Box>
        <Carousel
          autoPlaySpeed={5000}
          enableAutoPlay
          disableArrowsOnEnd={isMobileCarousel ? true : false}
          breakPoints={breakPoints}
          infinite={true}
          pagination={false}
          showArrows={!isMobileCarousel}
          renderArrow={(arrowProps) => <CarouselArrow {...arrowProps} />}
        >
          {createdArts &&
            createdArts.map((items) => {
              return <SingleCard key={items.id} {...items} />;
            })}
        </Carousel>
      </Box>
      {isMobileView && btnLabel && (
        <Stack alignItems="center" sx={{ marginTop: "1rem" }}>
          {" "}
          <Button
            variant="outlined"
            disableRipple
            size="small"
            sx={{
              color: "white",
              outlineColor: "#7879F1",
              borderColor: "#7879F1",
            }}
            component={Link}
            to="/browse"
          >
            {btnLabel}
          </Button>
        </Stack>
      )}
    </Box>
  );
};
export default Slider;
