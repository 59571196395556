import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import QuestionIcon from "../../assets/icons/question.svg";

const CustomAccordian = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Accordion
        sx={{
          background: "#FFFFFF1A",
          color: "#fff",
          border: "1px solid #1b1b1b",
          display: "grid",
          justifyItems: "space-between",
        }}
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: "white", fontSize: "30px" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            padding: "0px 10px 0px 10px",
            "& .Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <img src={QuestionIcon} width="20px" alt="" />
          &nbsp;&nbsp;&nbsp;{" "}
          <p>1. How Does the Artist Selection Process Work at xNFTs?</p>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#FFFFFF1A" }}>
          At xNFTs, we are committed to maintaining a high standard of quality
          and innovation for our collection of AI-generated art. To ensure our
          platform showcases the most captivating and groundbreaking artworks,
          we have implemented a selection process for artists wishing to join
          our community. Artists are invited to apply by submitting a portfolio
          of their work, along with a brief description of their artistic
          approach and how they incorporate AI into their creative process. Our
          team of art and technology experts reviews each application to select
          artists whose work aligns with our vision of pushing the boundaries of
          digital art through AI.
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          background: "#FFFFFF1A",
          color: "#fff",
          border: "1px solid #1b1b1b",
          display: "grid",
          justifyItems: "space-between",
        }}
        // defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: "white", fontSize: "30px" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            padding: "0px 10px 0px 10px",
            "& .Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <img src={QuestionIcon} width="20px" alt="" />
          &nbsp;&nbsp;&nbsp;{" "}
          <p>
            2. What File Formats Can I Upload for My AI-Generated Artwork on
            xNFTs?
          </p>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#FFFFFF1A" }}>
          xNFTs supports a wide range of file formats to accommodate the diverse
          creative outputs of AI-generated art. Artists can upload their work in
          popular formats such as JPEG, PNG for static images, GIF for animated
          pieces, and MP4 for video art. This flexibility allows artists to
          fully explore the potential of AI in creating varied and dynamic forms
          of digital art, ensuring that the platform caters to all types of
          AI-generated artworks.
        </AccordionDetails>
      </Accordion>{" "}
      <Accordion
        sx={{
          background: "#FFFFFF1A",
          color: "#fff",
          border: "1px solid #1b1b1b",
          display: "grid",
          justifyItems: "space-between",
        }}
        // defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: "white", fontSize: "30px" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            padding: "0px 10px 0px 10px",
            "& .Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <img src={QuestionIcon} width="20px" alt="" />
          &nbsp;&nbsp;&nbsp;{" "}
          <p>3. Why Should I Choose xNFTs for Minting My AI-Generated Art?</p>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#FFFFFF1A" }}>
          xNFTs distinguishes itself by focusing exclusively on AI-generated art
          and by offering a curated experience for both artists and collectors.
          Unlike general NFT marketplaces, xNFTs provides a specialized platform
          where artists working with AI can gain visibility among an engaged and
          appreciative audience. Additionally, our competitive commission
          structure ensures that artists are rewarded fairly for their
          innovations and creations. With xNFTs, you become part of a pioneering
          community dedicated to exploring the intersection of art and
          artificial intelligence.
        </AccordionDetails>
      </Accordion>{" "}
      <Accordion
        sx={{
          background: "#FFFFFF1A",
          color: "#fff",
          border: "1px solid #1b1b1b",
          display: "grid",
          justifyItems: "space-between",
        }}
        // defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: "white", fontSize: "30px" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            padding: "0px 10px 0px 10px",
            "& .Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <img src={QuestionIcon} width="20px" alt="" />
          &nbsp;&nbsp;&nbsp;{" "}
          <p>
            4. Can I Apply to Become an Artist on xNFTs if I'm New to AI Art?
          </p>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#FFFFFF1A" }}>
          Absolutely! xNFTs encourages artists at all stages of their journey in
          AI art to apply. Whether you are an experienced AI artist or new to
          utilizing AI in your creative process, we are interested in seeing
          your work and learning about your approach. Our selection process is
          designed to uncover artists with a unique vision and a commitment to
          exploring AI's potential in art. We provide resources and a supportive
          community to help artists of all levels grow and succeed in the
          exciting field of AI-generated art.
        </AccordionDetails>
      </Accordion>{" "}
    </Box>
  );
};
export default CustomAccordian;
